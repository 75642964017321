<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
    <el-row class="query-row" align="middle">
        <el-col :span="2">
            <el-input
                v-model="keywords.order_code"
                autocomplete="off"
                placeholder="订单号"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="3">
            <el-input
                v-model="keywords.product"
                autocomplete="off"
                placeholder="产品"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="2">
            <el-input
                v-model="keywords.job_code"
                autocomplete="off"
                placeholder="任务单号"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="2">
            <el-select
                v-model="keywords.isError"
                autocomplete="off"
                clearable
                placeholder="错误过滤">
                <el-option label="无错误" value="0" />
                <el-option label="有错误" value="1" />
                <el-option label="全部" value="" />
            </el-select>
        </el-col>
        <el-col :span="2">
            <el-select
                v-model="keywords.isFinished"
                autocomplete="off"
                clearable
                placeholder="完成状态">
                <el-option label="已完成" value="是" />
                <el-option label="未完成" value="否" />
                <el-option label="全部" value="" />
            </el-select>
        </el-col>
        <el-col :span="3">
            <el-select
                v-model="keywords.dateOffState"
                autocomplete="off"
                clearable
                multiple
                collapse-tags
                collapse-tags-tooltip
                placeholder="延期状态">
                <el-option
                    v-for="(item) in shipmentCondtion()" :key="item.value"
                    :label="item.label" :value="item.value">
                    <div class="el-select-option-div">
                        <el-text
                            :style="{'background-color':item.color,
                                     'flex-basis':'45%',
                                     'text-align':'left'
                            }">
                            {{ item.label }}
                        </el-text>
                        <el-text
                            style="flex-basis:45%;text-align:left">
                            {{ item.ft }}
                        </el-text>
                    </div>
                </el-option>
            </el-select>
        </el-col>
        <el-col :span="3">
            <div class="date-range">
                <el-date-picker
                    v-model="keywords.start"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="交期"
                    end-placeholder=""
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    size="default" />
            </div>
        </el-col>
        <el-col :span="3" />
    </el-row>
    <div class="batch-operate">
        <el-button
            v-if="jobList.some((item) => item.checked)"
            type="primary"
            @click="markedCalced(true)">
            标记参与计算
        </el-button>
        <el-button
            v-if="jobList.some((item) => item.checked)"
            type="warning"
            @click="markedCalced(false)">
            标记不计算
        </el-button>
        <el-button
            v-if="jobList.some((item) => item.checked)"
            type="danger"
            @click="deleteBatchJobs">
            批量删除
        </el-button>
    </div>
    <el-row class="table-container">
        <ListTable
            ref="vTableRef" :records="jobList" :options="vtableOptions"
            @onClickCell="handleClickCell"
            @onDropdownMenuClick="handleDropdownMenuClick"
            @onMouseEnterCell="handleMouseEnterCell">
            <ListColumn
                :field="'checked'" :title="''" cell-type="checkbox"
                header-type="checkbox" :width="40"
                :style="{size:16,
                         checkboxStyle:{checkedFill:'#409eff',defaultFill:'#ffffff'}}" />
            <ListColumn
                :title="'序号'" :field-format="(rec,col,row)=>row"
                :disable-hover="true"
                cursor="pointer"
                :width="60"
                :header-style="{fontSize:14}"
                :style="{bgColor:args=>calcErrorColor(args),fontSize:12}" />
            <ListColumn :field="'order_code'" :title="'订单号'" :width="120" />
            <ListColumn :field="'job_code'" :title="'任务号'" :width="120" />
            <ListColumn
                :field="'product'" :title="'产品'" :width="300"
                :icon="prdSyncIcon" />
            <ListColumn :field="'quantity'" :title="'数量'" :style="{textAlign:'right'}" :width="80" />
            <ListColumn
                :field="'produced_quantity'"
                :style="{textAlign:'right'}"
                :title="'已生产'" :width="80" />
            <ListColumn
                :field="'is_finished'" :title="'完成'" width="80">
                <template #customLayout="{record, height, width }">
                    <Group
                        :height="height"
                        :width="width"
                        display="flex"
                        flex-direction="row"
                        align-items="center"
                        justify-content="center">
                        <Group
                            :height="height*0.6"
                            :width="width*0.8"
                            display="flex"
                            :fill="record.is_finished!=='是'?'#13ce66':'#ff4949'"
                            :corner-radius="10"
                            :flex-direction="record.is_finished!=='是'?'row':'row-reverse'"
                            align-items="center"
                            justify-content="space-around"
                            cursor="pointer"
                            style="padding:0 10px;"
                            @mousedown="switchFinishedState($event,record)">
                            <Text
                                :font-size="14"
                                fill="#ffffff"
                                :text="record.is_finished" />
                            <Group
                                :height="20"
                                :width="20"
                                :corner-radius="20"
                                fill="white" />
                        </Group>
                    </Group>
                </template>
            </ListColumn>
            <ListColumn
                :field="'isIncludedInCalculation'"
                :header-style="{fontSize:14,padding:0,textAlign:'center'}"
                :title="'参与计算'" :width="80">
                <template #customLayout="{record, height, width }">
                    <Group
                        :height="height"
                        :width="width"
                        display="flex"
                        flex-direction="row"
                        align-items="center"
                        justify-content="center">
                        <Group
                            :height="height*0.6"
                            :width="width*0.8"
                            display="flex"
                            :fill="record.isIncludedInCalculation?'#13ce66':'#ff4949'"
                            :corner-radius="10"
                            :flex-direction="record.isIncludedInCalculation?'row':'row-reverse'"
                            align-items="center"
                            justify-content="space-around"
                            :cursor="cursorState(record)"
                            style="padding:0 10px;"
                            @mousedown="switchCalcState($event,record)">
                            <Text
                                :font-size="14"
                                fill="#ffffff"
                                :text="record.isIncludedInCalculation?'是':'否'" />
                            <Group
                                :height="20"
                                :width="20"
                                :corner-radius="20"
                                fill="white" />
                        </Group>
                    </Group>
                </template>
            </ListColumn>
            <ListColumn
                :show-sort="true"
                :sort="onSort"
                :field="'shipment_date'" :title="'计划交期'" :width="135">
                <template #customLayout="{record, height, width }">
                    <Group
                        :height="height"
                        :width="width"
                        display="flex"
                        flex-direction="row"
                        align-items="center"
                        justify-content="center">
                        <Group
                            :height="height*0.8"
                            :width="width*0.8"
                            display="flex"
                            align-items="center"
                            justify-content="space-around"
                            :fill="delayClassState(record)"
                            :line-width="2"
                            style="padding:0 10px;">
                            <Text
                                :font-size="12"
                                fill="black"
                                :text="record.shipment_date" />
                            <Group
                                v-if="offsetDays(record)!==''"
                                :height="20"
                                :width="1"
                                fill="black" />
                            <Text
                                :font-size="12"
                                fill="black"
                                :text="offsetDays(record)" />
                        </Group>
                    </Group>
                </template>
            </ListColumn>
            <ListColumn
                :field="'preMacs'"
                :header-style="{fontSize:14,padding:0,textAlign:'center'}"
                :title="'预排产机台'" :width="120">
                <template #customLayout="{record, height, width }">
                    <Group
                        :height="height"
                        :width="width"
                        display="flex"
                        flex-direction="row"
                        align-items="center"
                        justify-content="center">
                        <Group
                            :height="height*0.8"
                            :width="width*0.8"
                            display="flex"
                            :fill="showPreMacName(record.preMacs)[0]"
                            :corner-radius="10"
                            align-items="center"
                            justify-content="space-around"
                            cursor="pointer"
                            @mousedown="openMachineSelector($event,record)">
                            <Text
                                :font-size="12"
                                fill="#ffffff"
                                :text="showPreMacName(record.preMacs)[1]" />
                        </Group>
                    </Group>
                </template>
            </ListColumn>
            <ListColumn
                :field="'rollerWidth'"
                :title="'版宽'" :width="80" :field-format="(record)=>record?.process_craft?.['版宽']" />
            <ListColumn
                :field="'colorCount'"
                :title="'色数'" :width="80" :field-format="(record)=>record?.process_craft?.['色数']" />
            <ListColumn
                :field="'colors'"
                :style="{autoWrapText:true, fontSize:12}"
                :title="'色序'" :width="200"
                :field-format="(record)=>record?.process_craft?.['色序']" />
            <ListColumn
                :field="'remark'"
                :title="'备注'" :width="80" :style="{autoWrapText:true}" />
            <ListColumn
                :field="'op'" :title="'操作'" :width="100">
                <template #customLayout="{record, height, width }">
                    <Group
                        :height="height"
                        :width="width"
                        display="flex"
                        flex-direction="row"
                        align-items="center"
                        justify-content="space-around">
                        <Image
                            :width="20"
                            :height="20"
                            :image="sEdit"
                            cursor="pointer" />
                        <Image
                            :width="20"
                            :height="20" :image="sLock"
                            :cursor="cursorState(record)" />
                        <Image
                            :width="20" :height="20"
                            :image="sDelete" cursor="pointer" />
                    </Group>
                </template>
            </ListColumn>
        </ListTable>
    </el-row>
    <el-row class="vtable-footer" :gutter="1" align="middle">
        <el-col :span="3">
            <div>
                参与计算: {{ jobCalcQuantity() }} 万米
            </div>
        </el-col>
        <el-col :span="2">
            <div>
                参与计算: {{ uWbStore.jobList.filter(d=>d.isIncludedInCalculation).length }}
            </div>
        </el-col>
        <el-col v-for="(item) in jobPreMacShow" :key="item.name" :span="2">
            <div style="font-size:0.8rem;background-color: #33b5e5;">
                {{ item.name }}:
                {{ (item.quantity/10000).toFixed(2) }}万米|{{ item.taskNumber }}
            </div>
        </el-col>
    </el-row>
    <productOpt ref="refProductOpt" />
    <JobListEdit ref="refJobEdit" />
    <LockAddOpt
        :dialog-visible="showAddLock"
        :plan-date="uWbStore.g_plan_date"
        :job-info="jobInfo" @close="showAddLock=false" />
    <selectPreMac
        v-model:visible="isMachineSelectorOpen"
        :machines="currentPreMac"
        @save="handleSave" />
</template>
<script setup>
import {ListTable, ListColumn, Group, Text, Image} from '@visactor/vue-vtable';
import * as VTable from '@visactor/vtable';
import SchdStore from '../stores/schdtabular.js';
import {
    ElMessageBox,
} from 'element-plus';
import {getErpPrd, getPrdInfo} from '../assets/js/erp2Schd.js';
import {notify} from '../assets/js/utils.js';
import {
    editIcon, refreshIcon, questionError,
    plusIcon, generateSVG,
} from '../assets/js/vTableCustomIcons.js';
import productOpt from './product-opt.vue';
import JobListEdit from './job-list-edit.vue';
import LockAddOpt from './lock-add-opt.vue';
import selectPreMac from './select-pre-mac.vue';
import {ref, computed, defineProps, onBeforeMount} from 'vue';
import dayjs from 'dayjs';
// #region 加载svg
const sEdit = ref('');
const sLock = ref('');
const sDelete = ref('');
onBeforeMount(() => {
    sEdit.value = generateSVG('edit', 'rgb(64, 158, 255)');
    sLock.value = generateSVG('lock', 'rgb(64, 158, 255)');
    sDelete.value = generateSVG('delete', 'rgb(255, 77, 79)');
});
// #endregion

// #region vTavle 初始化,事件绑定
const vTableRef = ref(null);

// 右键菜单
const handleDropdownMenuClick = (args) => {
    const {menuKey, row, col} = args;
    const vTableInstance = vTableRef.value.vTableInstance;
    if (menuKey === 'copyToClipboard') {
        const _text = vTableInstance.getCellOriginValue(col, row);
        navigator.clipboard.writeText(_text);
        return;
    }
    if (menuKey === 'edit') {
        editJob(vTableInstance.getRecordByCell(col, row));
        return;
    }
    if (menuKey === 'delete') {
        deleteJob(vTableInstance.getRecordByCell(col, row));
    }
};

const handleClickCell = (args) => {
    const {field, target, originData, cellLocation} = args;
    console.log(field, target, originData, args);
    if (field === 'checked' && target.name === 'checkbox' && cellLocation === 'body') {
        originData.checked = !originData.checked;
        return;
    }
    if (field === 'checked' && target.name === 'checkbox' && cellLocation === 'columnHeader') {
        jobList.value.forEach((item) => {
            item.checked = target.attribute.checked;
        });
        return;
    }
    // 产品信息同步或编辑
    if (field === 'product' && target.type === 'image') {
        if (target.name === 'refresh') {
            // 同步
            syncFromERP(originData);
            return;
        }
        if (target.name === 'edit') {
            // 编辑
            syncFromERP(originData, 'edit');
            return;
        }
        if (target.name === 'plus') {
            // 编辑
            syncFromERP(originData, 'add');
        }
    }
    // 处理任务编辑逻辑
    if (field === 'op' && target.type === 'image') {
        if (target.attribute.image === sEdit.value) {
            editJob(originData);
            return;
        }
        if (target.attribute.image === sDelete.value) {
            deleteJob(originData);
            return;
        }
        if (target.attribute.image === sLock.value) {
            addLock(originData);
        }
    }
};
const handleMouseEnterCell = (args) => {
    const {col, row} = args;
    // 序号列
    const vTableInstance = vTableRef.value.vTableInstance;
    if (col === 1 && row > 0) {
        const record = vTableInstance.records[row - 1];
        if (record.error.length === 0) return;
        const rect = vTableInstance.getVisibleCellRangeRelativeRect({col, row});
        vTableInstance.showTooltip(col, row, {
            content: '错误信息：' + record.error.map((err) => err.info).join('|'),
            referencePosition: {rect, placement: VTable.TYPES.Placement.right}, // TODO
            disappearDelay: 100,
            style: {
                bgColor: 'red',
                color: 'white',
                font: 'normal bold normal 14px/1 STKaiti',
                arrowMark: true,
            },
        });
    }
};
const vtableOptions = {
    defaultRowHeight: 45,
    widthMode: 'standard',
    tooltip: {
        isShowOverflowTextTooltip: true,
    },
    rightFrozenColCount: 1,
    dragHeaderMode: 'column',
    emptyTip: {text: '暂无数据'},
    theme: VTable.themes.DEFAULT.extends({
        headerStyle: {
            textAlign: 'center',
            fontSize: 14,
        },
        bodyStyle: {
            fontSize: 14,
            textAlign: 'left',
        },
        scrollStyle: {
            hoverOn: false,
            barToSide: false,
        },
    }),
    menu: {
        contextMenuItems: (field, row) => {
            const contextKeys = ['product', 'job_code', 'order_code'];
            if (contextKeys.indexOf(field) === -1 || row === 0) return;
            return [
                {text: '复制', menuKey: 'copyToClipboard'},
                {text: '编辑', menuKey: 'edit'},
                {text: '删除', menuKey: 'delete'},
            ];
        },
    },
};
// #endregion
// #region vue 数据初始化
const uWbStore = SchdStore();
const jobListBase = uWbStore.jobList;
const jobList = computed(() => {
    jobListBase.forEach((item) => {
        // 将错误信息压缩为一个字符串,然后在生成tag的时候进行解压
        if (item.error.length > 0) {
            item.children = [{'detail': item.error.map((err) => err.info).join('@@')}];
        } else {
            item.children = null;
        }
    });
    let _jobList = jobListBase;
    const {order_code, product, job_code, machine, isError, isFinished, start} = keywords.value;
    const _orderCode = order_code.trim();
    const _product = product.trim();
    const _jobCode = job_code.trim();
    if (_orderCode || _product || _jobCode || machine || isError || isFinished) {
        _jobList = jobListBase.filter((item) => {
            const matchesOrderCode = !_orderCode || item.order_code.includes(_orderCode);
            const matchesProductName = !_product || item?.product?.includes(_product);
            const matchesJobCode = !_jobCode || item?.job_code?.includes(_jobCode);
            const matchesMachine = !machine || item?.machine?.includes(machine);
            const filterError = isError === ''
                ? true
                : (isError === '0' ? item.error.length === 0 : item.error.length > 0);
            const filterFinished = isFinished === '' ? true : item.is_finished === isFinished;
            // 只有当所有检查都通过时，才返回 true
            return matchesOrderCode && matchesProductName && matchesJobCode
             && matchesMachine && filterError && filterFinished;
        });
    }
    if (start && start.length === 2) {
        const startDate = dayjs(start[0]);
        const endDate = dayjs(start[1]);
        _jobList = _jobList.filter((job) => {
            const jobDate = dayjs(job.shipment_date);
            return jobDate.diff(startDate, 'days') >= 0 && endDate.diff(jobDate, 'days') >= 0;
        });
    }
    const {dateOffState} = keywords.value;
    if (dateOffState.length > 0) {
        _jobList = _jobList.filter((job) => {
            const days = dayjs(job.shipment_date).diff(uWbStore.g_plan_date, 'day');
            const c = getShipDateX(days).value;
            return dateOffState.indexOf(c) > -1;
        });
    }
    return _jobList;
});
const keywords = ref({
    'order_code': '',
    'product': '',
    'machine': '',
    'job_code': '',
    'isError': '',
    'isFinished': '否',
    'start': [],
    'dateOffState': [],
});
// #endregion

// #region 模拟switch,完成|参与计算
const switchCalcState = (e, record) => {
    if (record.is_finished !== '否' || record.error.length > 0) {
        return;
    }
    record.isIncludedInCalculation = !record.isIncludedInCalculation;
    e.stopPropagation();
};
const switchFinishedState = (e, record) => {
    if (record.is_finished === '是') {
        record.is_finished = '否';
    } else {
        record.isIncludedInCalculation = false;
        record.is_finished = '是';
    }
    e.stopPropagation();
};
// #endregion

// #region 交货期处理逻辑
/**
 * 根据交货日期计算任务的状态颜色。
 *
 * @param {Object} record - 任务记录，包含交货日期。
 * @param {string} record.shipment_date - 任务的交货日期。
 * @returns {Object} - 状态颜色
 * 类似{
            'label': '严重延误',
            range: [-Infinity, -10],
            value: 'severe-delay',
            color: '#ff4d4d',
            'ft': '延10天及以上',
        }
 */
const delayClassState = ({shipment_date}) => {
    const startDate = dayjs(uWbStore.g_plan_date);
    if (!startDate.isValid()) return '';
    const rShipDate = dayjs(shipment_date);
    if (!startDate.isValid()) return '';
    const delayDays = rShipDate.diff(startDate, 'day');
    // 严重延误的任务
    return getShipDateX(delayDays).color;
};
/**
 * 计算任务交货日期与计划日期的偏离天数。
 *
 * @param {Object} record - 任务记录，包含交货日期。
 * @param {string} record.shipment_date - 任务的交货日期。
 * @returns {string} - 偏离天数（如 "+3" 或 "-2"）。
 */
const offsetDays = ({shipment_date}) => {
    // 根据交货日期与计划日期与计算天数进行比对,来计算偏离天数
    const startDate = dayjs(uWbStore.g_plan_date);
    if (!startDate.isValid()) return '';
    const rShipDate = dayjs(shipment_date);
    if (!startDate.isValid()) return '';
    const diffDays = rShipDate.diff(startDate, 'day');
    if (diffDays >= 0 && diffDays < uWbStore.calculationDays) return '';
    if (diffDays < 0) return diffDays;
    return `+${diffDays - uWbStore.calculationDays + 1}`;
};
/**
 * 获取交货状态的条件列表。
 *
 * @returns {Array} - 交货状态的条件列表，包含标签、范围、值、颜色等信息。
 */
const shipmentCondtion = () => {
    return [
        {
            'label': '严重延误',
            range: [-Infinity, -10],
            value: 'severe-delay',
            color: '#ff4d4d',
            'ft': '延10天及以上',
        },
        {
            'label': '中等延误',
            range: [-9, -4],
            value: 'moderate-delay',
            color: '#ffbb33',
            'ft': '延4至10天',
        },
        {
            'label': '延误',
            range: [-3, -1],
            value: 'mild-delay',
            color: '#ffdd57',
            'ft': '延1至3天',
        },
        {
            'label': '正常',
            range: [0, uWbStore.calculationDays - 1],
            value: 'on-time',
            color: '#70e970',
            'ft': '计划天数内',
        },
        {
            'label': '未来',
            range: [uWbStore.calculationDays, Infinity],
            value: 'infuture',
            color: '#33b5e5',
            'ft': '超计算天数',
        },
    ];
};
/**
 * 根据偏离天数获取交货状态。
 *
 * @param {number} days - 偏离天数。
 * @returns {Object} - 交货状态的条件对象。
 * @throws {Error} - 如果偏离天数不在任何范围内，抛出错误。
 */
const getShipDateX = (days) => {
    for (const condtion of shipmentCondtion()) {
        if (condtion.range[0] <= days && days <= condtion.range[1]) {
            return condtion;
        }
    }
    throw new Error(`${days} is not in any range`);
};
// #endregion

// #region 产品信息同步|编辑
const refProductOpt = ref(null);
const props = defineProps({
    isErpSync: {
        type: Boolean,
        default: false,
    },
});
const syncFromERP = async (row, model = 'syncErp') => {
    let prdData;
    if (model === 'syncErp') {
        const order = uWbStore.orderList.find((order) => {
            return order.order_code === row.order_code;
        });
        let prd = splitStringByLastColon(row.product_query);
        if (order) {
            prd = splitStringByLastColon(order.product_query);
        }
        const prdinfo = await getErpPrd(prd.p_code, prd.v_name);
        if (prdinfo.length === 0) {
            notify('error', '同步出现错误');
            return;
        }
        const _remote = await getPrdInfo(row.product);
        if (_remote.length > 0) {
            // 判断是否有同名的产品
            const sameNamePrd = _remote.find((prd) => {
                return prd.name === row.product;
            });
            if (sameNamePrd) {
                notify('error', `与产品编码:${sameNamePrd.code}的产品的名称冲突,注意ERP系统中的同名产品与自动排产中的编号是否一致`);
                return;
            }
        }
        // 由于历史原因或者先创建产品,这里可能会出现同名的产品信息
        prdinfo[0].code = `${prdinfo[0].code}:${prd.v_name}`;
        prdData = prdinfo[0];
    }
    if (model === 'edit') {
        const _remote = await getPrdInfo(row.product_info.code);
        if (_remote.length === 0) {
            notify('error', `编辑出现错误,没有找到编号:${row.product_info.code}的产品,请刷新页面再次尝试`);
            return;
        }
        prdData = _remote[0];
        if (props.isErpSync && prdData.name !== row.product) {
            prdData.name = row.product;
        }
    }
    if (model === 'add') {
        prdData = {'name': row.product};
    }
    const data = await refProductOpt.value.show({
        title: '产品同步添加',
        data: prdData,
        product_list: [],
    });
    if (data) {
        // 刷新
        row.product_info = data;
        row.product = row.product_info.name;
        await uWbStore.updateJob(row.serial_number, row);
        // 同步处理其他地方的相同的产品的错误信息
        // 处理订单信息的
        const orders = uWbStore.orderList.filter((item) => {
            return (item.product === row.product_info.name
                    || item.product === row.product_info.code);
        });
        await uWbStore.updatePrdInfo(orders, row.product_info);
        const jobes = uWbStore.jobList.filter((item) => {
            return (item.product === row.product_info.name
                    || item.product === row.product_info.code) && item.job_code !== row.job_code;
        });
        await uWbStore.updatePrdInfo(jobes, row.product_info);
        uWbStore.checkOrder(orders);
        uWbStore.checkJob(jobes);
    }
};
/**
 * 通过最后一个冒号（':'）将产品字符串分割为两部分。
 *
 * 此函数通常用于将产品代码与其变体名称分开。
 * 例如，如果输入是 "ABC123:Red"，它将返回一个对象，其中 `p_code` 为 "ABC123"，
 * `v_name` 为 "Red"。如果字符串中没有冒号，则整个字符串被视为产品代码（`p_code`），
 * 变体名称（`v_name`）设置为空字符串。
 *
 * @param {string} product - 要分割的产品字符串，可能包含冒号。
 * @returns {Object} 包含两个属性的对象：
 *   - `p_code`: 产品代码，即最后一个冒号之前的部分。
 *   - `v_name`: 变体名称，即最后一个冒号之后的部分。如果不存在冒号，则为空字符串。
 */
const splitStringByLastColon = (product) => {
    const parts = product.split(':');
    if (parts.length > 1) {
        const p_code = parts.slice(0, -1).join(':');
        const v_name = parts[parts.length - 1];
        return {
            p_code,
            v_name,
        };
    } else {
        return {
            p_code: product,
            v_name: '',
        };
    }
};
const isPrdNotFound = ({error}) => {
    return error.findIndex((err) => {
        return err.tag === 'product-not-found';
    }) > -1;
};
const prdSyncIcon = (args) => {
    const {row, table} = args;
    if (isPrdNotFound(table.records[row - 1])) {
        if (props.isErpSync) {
            return refreshIcon;
        }
        return plusIcon;
    } else {
        const _record = table.records[row - 1];
        if (props.isErpSync && _record?.product_info && _record.product !== _record.product_info?.name) {
            return [editIcon, questionError];
        }
        return editIcon;
    }
};
// #endregion

// #region 任务删除,编辑,锁定
// 操作列组件逻辑
const refJobEdit = ref(null);
const deleteJob = ({serial_number}) => {
    const job = uWbStore.getJob(serial_number);
    ElMessageBox.confirm(
        `删除本行数据？${job.job_code}`,
        '提醒',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        },
    )
        .then(() => {
            uWbStore.deleteJob(job.serial_number);
            notify('success', '删除成功', 1500);
        })
        .catch((msg) => {
            notify('info', msg === 'cancel' ? '取消' : msg, 1500);
        });
};
const editJob = async ({serial_number}) => {
    const job = uWbStore.getJob(serial_number);
    const res = await refJobEdit.value.show({
        title: '任务修改',
        data: Object.assign({}, job),
    });
    if (res) {
        // 更新数据
        uWbStore.updateJob(serial_number, res);
    }
};
const jobInfo = ref(null);
const showAddLock = ref(false);
const addLock = ({serial_number, error, is_finished}) => {
    if (error.length > 0 || is_finished === '是') return;
    const job = uWbStore.getJob(serial_number);
    jobInfo.value = {...job};
    showAddLock.value = true;
};
// #endregion

// #region 数据处理函数

/**
 * 根据任务记录获取鼠标指针状态。
 *
 * @param {Object} record - 任务记录，包含完成状态和错误信息。
 * @param {string} record.is_finished - 任务的完成状态。
 * @param {Array} record.error - 任务的错误信息列表。
 * @returns {string} - 鼠标指针状态（如 'not-allowed' 或 'pointer'）。
 */
const cursorState = (record) => {
    if (record.is_finished === '是' || record.error.length > 0) {
        return 'not-allowed';
    } else {
        return 'pointer';
    }
};

/**
 * 根据任务记录计算错误颜色。
 *
 * @param {Object} args - 参数对象，包含行索引。
 * @param {number} args.row - 行索引。
 * @returns {string} - 错误颜色（如 '#f56c6c'）。
 */
const calcErrorColor = (args) => {
    if (jobList.value[args.row - 1].error.length > 0) {
        return '#f56c6c';
    }
};
// #endregion

// #region 预排产机台处理
// 预排产机台逻辑
const showPreMacName = (preMacs) => {
    if (!preMacs || preMacs.length === 0) {
        return ['#ff4d4d', '无可用机台', 'error']; // 如果 preMac 不存在或为空，返回提示
    }
    if (preMacs.length === 1) {
        return ['#70e970', preMacs[0].name, 'sucess'];
    }
    const fd = preMacs.find((m) => m.selected);
    return fd ? ['#70e970', fd.name, 'sucess'] : ['#ff4d4d', '选择机台', 'danger'];
};
const isMachineSelectorOpen = ref(false);
const currentPreMac = ref([]); // 当前选中的 preMac 数据
let currentRowSerial = null; // 当前操作的行索引
// 打开机台选择子组件
const openMachineSelector = (e, row) => {
    const [,, res] = showPreMacName(row.preMacs);
    if (res === 'error') return;
    currentRowSerial = row.serial_number;
    currentPreMac.value = row.preMacs || [];
    isMachineSelectorOpen.value = true;
};

// 保存选择的机台
const handleSave = (selectedId) => {
    if (currentRowSerial !== null && uWbStore.getJob(currentRowSerial).preMacs) {
        uWbStore.getJob(currentRowSerial).preMacs.forEach((machine) => {
            machine.selected = machine.code === selectedId;
        });
    }
};
// #endregion

// #region v-table footer 处理
/**
 * 计算参与计算的任务的总米数。
 *
 * @returns {string} - 参与计算的任务的总米数（单位：万米）。
 */
const jobCalcQuantity = () => {
    const _jobs = uWbStore.jobList.filter(d => d.isIncludedInCalculation);
    if (_jobs.length === 0) return 0;
    const res = _jobs.reduce((acc, cur) => acc + cur.quantity - cur.produced_quantity, 0);
    // 对res进行单位转换
    return (res / 10000).toFixed(1);
};
/**
 * 获取参与计算的机台展示信息。
 *
 * @returns {Array} - 参与计算的机台展示信息列表。
 */
const jobPreMacShow = computed(() => {
    const tj = jobListBase.filter((job) => job.isIncludedInCalculation);
    const res = new Map();
    for (const job of tj) {
        if (job.preMacs.length === 0) continue;
        // 判断是否已选择机台
        if (job.preMacs.filter(m => m?.selected).length === 0 && job.preMacs.length > 1) {
            if (!res.get('cross-task-macs')) {
                res.set('cross-task-macs', {
                    name: '多机台任务',
                    quantity: 0,
                    taskNumber: 0,
                });
            }
            const _t = res.get('cross-task-macs');
            _t.quantity += (job.quantity - job.produced_quantity);
            _t.taskNumber += 1;
            continue;
        }
        const selectMac = job.preMacs.find(m => m?.selected);
        if (selectMac) {
            if (!res.get(selectMac.code)) {
                res.set(selectMac.code, {
                    name: selectMac.name,
                    quantity: 0,
                    taskNumber: 0,
                });
            }
            const _t = res.get(selectMac.code);
            _t.quantity += (job.quantity - job.produced_quantity);
            _t.taskNumber += 1;
            continue;
        }
        for (const _mc of job.preMacs) {
            if (!res.get(_mc.code)) {
                res.set(_mc.code, {
                    name: _mc.name,
                    quantity: 0,
                    taskNumber: 0,
                });
            }
            const _t = res.get(_mc.code);
            _t.quantity += (job.quantity - job.produced_quantity);
            _t.taskNumber += 1;
        }
    }
    return Array.from(res.values());
});
// #endregion

// #region 排序处理
/**
 * 处理排序逻辑。
 *
 * @param {any} v1 - 第一个值。
 * @param {any} v2 - 第二个值。
 * @param {string} order - 排序顺序（如 'asc' 或 'desc'）。
 * @returns {number} - 排序结果（-1、0 或 1）。
 */
const onSort = (v1, v2, order) => {
    if (order === 'desc') {
        return v1 === v2 ? 0 : v1 > v2 ? -1 : 1;
    }
    return v1 === v2 ? 0 : v1 > v2 ? 1 : -1;
};
// #endregion

// #region 批量操作
const markedCalced = (val) => {
    for (const job of jobList.value) {
        if (job.checked && job.is_finished !== '是' && job.error.length === 0) {
            job.isIncludedInCalculation = val;
        }
    }
};
const deleteBatchJobs = () => {
    // 删除选中的所有任务信息
    const toDelJobs = jobList.value.filter((item) => item.checked);
    ElMessageBox.confirm(
        `删除${toDelJobs.length}行数据？`,
        '提醒',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        },
    )
        .then(() => {
            toDelJobs.forEach((item) => {
                uWbStore.deleteJob(item.serial_number);
            });
            notify('success', '删除成功', 1500);
        })
        .catch((msg) => {
            notify('info', msg === 'cancel' ? '取消' : msg, 1500);
        });
};
// #endregion
</script>
<style scoped>
.table-col {
    border-left: 1px solid #ebeef5;
}
.batch-operate {
    position: absolute;
    top:20px;
    right:25px;
}
.product {
    font-size:0.8rem;
    text-align: left;
    text-wrap:nowrap;
}
.query-row {
    height: 50px;
}
.table-container {
    height: calc(100% - 95px);
    width:100%;
}
.vtable-footer {
    height:40px;
    margin-top: 5px;
}
.date-range {
    width:100%;
}
:deep(.date-range .el-input__wrapper) {
    width: 85% !important;
}

.vtable-footer>.el-col {
    border-radius: 4px;
    height:100%;
}
.vtable-footer>.el-col>div {
    border-radius: 10px;
    height:100%;
    font-size:1.2rem;
    background-color: #67c23a;
    align-items: center;
    display: flex;
    justify-content: center;
    color:whitesmoke;
}
</style>
