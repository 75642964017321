/* eslint-disable max-len */
const vTooltip = tooltip => {
    const t = {
        placement: 'top',
        title: '编辑',
        style: {
            font: '10px Arial',
            bgColor: 'white',
            color: '#333',
            arrowMark: true,
        },
    };
    return Object.assign(t, tooltip);
};
const vIcon = (
    svg,
    name,
    width,
    height,
    cursor,
    _tooltip,
    positionType = 'contentLeft',
) => {
    const tooltip = vTooltip(_tooltip);
    return {
        type: 'svg',
        positionType,
        name,
        width,
        height,
        cursor,
        svg,
        tooltip,
    };
};

// 传入颜色和类型返回相应的的svg字符串
const svgTemplates = {
    edit: color =>
        `<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="${color}" d="M832 512a32 32 0 1 1 64 0v352a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V160a32 32 0 0 1 32-32h352a32 32 0 0 1 0 64H192v640h640z"></path><path fill="${color}" d="m469.952 554.24 52.8-7.552L847.104 222.4a32 32 0 1 0-45.248-45.248L477.44 501.44l-7.552 52.8zm422.4-422.4a96 96 0 0 1 0 135.808l-331.84 331.84a32 32 0 0 1-18.112 9.088L436.8 623.68a32 32 0 0 1-36.224-36.224l15.104-105.6a32 32 0 0 1 9.024-18.112l331.904-331.84a96 96 0 0 1 135.744 0z"></path></svg>`,
    plus: color =>
        `<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="${color}" d="M480 480V128a32 32 0 0 1 64 0v352h352a32 32 0 1 1 0 64H544v352a32 32 0 1 1-64 0V544H128a32 32 0 0 1 0-64z"></path></svg>`,
    lock: color =>
        `<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="${color}" d="M224 448a32 32 0 0 0-32 32v384a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V480a32 32 0 0 0-32-32zm0-64h576a96 96 0 0 1 96 96v384a96 96 0 0 1-96 96H224a96 96 0 0 1-96-96V480a96 96 0 0 1 96-96"></path><path fill="${color}" d="M512 544a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V576a32 32 0 0 1 32-32m192-160v-64a192 192 0 1 0-384 0v64zM512 64a256 256 0 0 1 256 256v128H256V320A256 256 0 0 1 512 64"></path></svg>`,
    delete: color =>
        `<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="${color}" d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32zm448-64v-64H416v64zM224 896h576V256H224zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32m192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32"></path></svg>`,
    refresh: color =>
        `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="${color}" d="M771.776 794.88A384 384 0 0 1 128 512h64a320 320 0 0 0 555.712 216.448H654.72a32 32 0 1 1 0-64h149.056a32 32 0 0 1 32 32v148.928a32 32 0 1 1-64 0v-50.56zM276.288 295.616h92.992a32 32 0 0 1 0 64H220.16a32 32 0 0 1-32-32V178.56a32 32 0 0 1 64 0v50.56A384 384 0 0 1 896.128 512h-64a320 320 0 0 0-555.776-216.384z"></path></svg>`,
    question: color =>
        `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="${color}" d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896m23.744 191.488c-52.096 0-92.928 14.784-123.2 44.352-30.976 29.568-45.76 70.4-45.76 122.496h80.256c0-29.568 5.632-52.8 17.6-68.992 13.376-19.712 35.2-28.864 66.176-28.864 23.936 0 42.944 6.336 56.32 19.712 12.672 13.376 19.712 31.68 19.712 54.912 0 17.6-6.336 34.496-19.008 49.984l-8.448 9.856c-45.76 40.832-73.216 70.4-82.368 89.408-9.856 19.008-14.08 42.24-14.08 68.992v9.856h80.96v-9.856c0-16.896 3.52-31.68 10.56-45.76 6.336-12.672 15.488-24.64 28.16-35.2 33.792-29.568 54.208-48.576 60.544-55.616 16.896-22.528 26.048-51.392 26.048-86.592 0-42.944-14.08-76.736-42.24-101.376-28.16-25.344-65.472-37.312-111.232-37.312zm-12.672 406.208a54.272 54.272 0 0 0-38.72 14.784 49.408 49.408 0 0 0-15.488 38.016c0 15.488 4.928 28.16 15.488 38.016A54.848 54.848 0 0 0 523.072 768c15.488 0 28.16-4.928 38.72-14.784a51.52 51.52 0 0 0 16.192-38.72 51.968 51.968 0 0 0-15.488-38.016 55.936 55.936 0 0 0-39.424-14.784z"></path></svg>`,
    operation: color =>
        `<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="${color}" d="M389.44 768a96.064 96.064 0 0 1 181.12 0H896v64H570.56a96.064 96.064 0 0 1-181.12 0H128v-64zm192-288a96.064 96.064 0 0 1 181.12 0H896v64H762.56a96.064 96.064 0 0 1-181.12 0H128v-64zm-320-288a96.064 96.064 0 0 1 181.12 0H896v64H442.56a96.064 96.064 0 0 1-181.12 0H128v-64z"></path></svg>`,
};
function generateSVG(shapeName, color) {
    const template = svgTemplates[shapeName];
    if (!template) {
        throw new Error(`Shape "${shapeName}" not found.`);
    }
    return template(color);
}
const primary = 'rgb(64, 158, 255)';
const danger = 'rgb(255, 77, 79)';
// const _edit = generateSVG('edit', primary);
const svgEdit = ({width, height, color}) => {
    const svg = generateSVG('edit', color);
    return vIcon(
        svg,
        'edit',
        width,
        height,
        'pointer',
        {title: '编辑'},
        'contentLeft',
    );
};
const svgPlus = ({width, height, color}) => {
    const svg = generateSVG('plus', color);
    return vIcon(svg, 'plus', width, height, 'pointer', {title: '添加'});
};
const svgLock = ({width, height, color}) => {
    const svg = generateSVG('lock', color);
    return vIcon(
        svg,
        'lock',
        width,
        height,
        'pointer',
        {title: '锁定'},
        'left',
    );
};
const svgDelete = ({width, height, color}) => {
    const svg = generateSVG('delete', color);
    return vIcon(
        svg,
        'delete',
        width,
        height,
        'pointer',
        {title: '删除'},
        'left',
    );
};

const svgRefresh = ({width, height, color}) => {
    const svg = generateSVG('refresh', color);
    return vIcon(svg, 'refresh', width, height, 'pointer', {title: '同步'});
};
const question = ({width, height, color}) => {
    const svg = generateSVG('question', color);
    return vIcon(
        svg,
        'question',
        width,
        height,
        'pointer',
        {title: 'ERP导入产品名称与本地不一致,请修改'},
        'contentRight',
    );
};
const editIcon = svgEdit({width: 20, height: 20, color: primary});
const refreshIcon = svgRefresh({
    width: 20,
    height: 20,
    color: primary,
});
const questionError = question({width: 20, height: 20, color: danger});
const plusIcon = svgPlus({width: 20, height: 20, color: primary});
const lockIcon = svgLock({width: 20, height: 20, color: primary});
const deleteIcon = svgDelete({width: 20, height: 20, color: danger});
export {editIcon, refreshIcon, questionError, plusIcon, deleteIcon, lockIcon, generateSVG};
