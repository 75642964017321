/* eslint-disable no-unused-vars */
import axios from 'axios';
import dayjs from 'dayjs';

/**
 * @import {JobResult,ScheduleResult, OrderResult, ProductInfo} from './schdTypes.js';
 * @import {baseParam,Parameter,SpeedProperty,Speed,calendar,machine,ParamCnf} from './schdTypes.js';
 * @import {machineCalendar,processCalendar,processQuantity,produceMinutes} from './schdTypes.js';
 * @import {EstimateProcessSchedule,DaysPlan,matchRule} from './schdTypes.js';
 * @import {Craft} from './schdTypes.js';
 */

/**
 * @typedef {Map<any, produceMinutes>} produceMinutesMap
 */

function isValidTwoDimensionalArray(arr) {
    // 检查是否为数组
    if (!Array.isArray(arr)) return false;

    // 检查数组的每个元素是否为长度为2的数组，且每个子数组的元素都是数字
    return arr.every(item =>
        Array.isArray(item) // item 是否为数组
        && item.length === 2 // 子数组长度是否为2
        && item.every(num => typeof num === 'number'), // 子数组的每个元素是否为数字
    );
}

/**
 * @typedef {'一复' | '二复' | '三复'} ComplexLevel
 */
const glueTypes = ['普通', '水煮', '蒸煮'];
const complexTypes = ['干式', '无溶剂'];
const complexLevels = ['一复', '二复', '三复'];
const printVarnish = ['有', '无'];
class Process {
    /**
     * 基本工序
     * @param {object} processData - 来源产品信息中的craft中的item
     */
    constructor(data) {
        const {process, parmCnf, productName} = data;
        this.productName = productName;
        this.initPoperty(process);
        // 验证通用属性
        this.validateCommonProperties();
        /** @type {Parameter} */
        this.parameter = parmCnf.params;
        /** @type {number} 生产完成之后需要的额外工时 */
        this.afterProducedExtraMinutes = 0;
        /** @type {number} 工序间隔时间 */
        this.processGapMiuntes = 120;
        /** @type {Process[]} 直接的后工序 */
        this.directSuccessors = [];
        /** @type {Process[]} 直接的前工序 */
        this.directPredecessors = [];
        this.isMainPath = false;
        this.isMainHead = false;
        this.initMachines(parmCnf);
        this.initCalendars(parmCnf);
        this.initSpeeds(parmCnf);
    }

    initPoperty(process) {
        for (const [key, value] of Object.entries(process)) {
            if (key === 'output_ratio') {
                this[key] = value instanceof String
                    ? Number(value)
                    : value;
                continue;
            }
            if (key === '色序') {
                this[key] = this.colorsToArray(value);
                this.色数 = this[key].length;
                continue;
            }
            this[key] = value;
        }
    }

    /**
     * @param {string|string[]} colors
     */
    colorsToArray(colors) {
        if (Array.isArray(colors)) return colors;
        if (typeof colors === 'string') {
            return colors.split(',');
        }
        return [];
    }

    /**
     * 验证基本属性
     * @throws {Error} 抛出错误
     */
    validateCommonProperties() {
        // 必须有last_porcess且为数组
        if (!Array.isArray(this.last_process)) {
            throw new Error('属性不合法:last_process必须为数组');
        }
        if (!this.cate_code) {
            throw new Error('缺少属性: cate_code');
        }

        if (!this.name) {
            throw new Error('缺少属性: name');
        }

        if (isNaN(this.output_ratio) || this.output_ratio <= 0) {
            throw new Error('属性错误: output_ratio 必须为大于0的数');
        }
    }

    /**
     * @param {processQuantity} [quantity] - 投入量
     * @returns {produceMinutesMap} - produceMinutesMap
     */
    calcProduceMinutes(quantity) {
        const _pp = new Map();
        if (this.isUnconcernedWithQuantity) {
            _pp.set(null, {'machineCode': null, 'minutes': 24 * 60, quantity});
            return _pp;
        }
        Object.keys(this.speeds).forEach((key) => {
            if (this.getValidMachines().length === 0) {
                _pp.set(null,
                    {
                        'machineCode': null,
                        'minutes': quantity.input / this.speeds[key],
                        'speedProp': {
                            'label': key,
                            'value': this.speeds[key],
                        },
                        quantity,
                    },
                );
            } else {
                _pp.set(key, {
                    'machineCode': key,
                    'minutes': quantity.input / this.speeds[key],
                    'speedProp': {
                        'label': key,
                        'value': this.speeds[key],
                    },
                    quantity,
                });
            }
        });
        return _pp;
    }

    /**
     * 初始化机台信息
     */
    initMachines({machines}) {
        /** @type {machine[]} */
        this.machines = machines
            .filter(item => item.category.code === this.cate_code);
        const needMacsPorcess = ['print', 'complex'];
        if (this.machines.length === 0 && needMacsPorcess.includes(this.cate_code)) {
            throw new Error(`${this.productName}的${this.cate_name}没有设置机台信息！`);
        }
    }

    initCalendars({calendar}) {
        // 如果没有找到该工序的日历信息，则使用默认的日历信息
        let _cals = calendar.filter(item => item.category.has(this.cate_code));
        if (_cals.length === 0) {
            _cals = this.baseCalender(calendar);
        }
        // 如果没有任何日历信息,则抛出错误
        if (_cals.length === 0) {
            throw new Error(`${this.cate_name}没有设置日历信息！`);
        }
        const cals = this.getValidMachines().map((machine) => {
            return {
                'calendarList': _cals.filter(cal => cal.machineCodes.has(machine.code)),
                machineCode: machine.code,
            };
        });
        /** @type {machineCalendar[]} */
        this.calnedars = cals;
        if (cals.length === 0) {
            this.calnedars = [{'calendarList': _cals, machineCode: null}];
        }
        // 将calendars转换为一个map,方便日历的查找
        this.calendarsMap = new Map(this.calnedars.map(item => [item.machineCode, item]));
    }

    /**
     *
     * @param {ParamCnf} paramCnf
     */
    initSpeeds(paramCnf) {
        // 初始化速度信息
        const {speeds, params} = paramCnf;
        const processSpeed = speeds.find((sp) => {
            return sp.category.code === this.cate_code;
        });
        // 没有找到速度参数配置
        if (typeof processSpeed === 'undefined') {
            this.speeds = {};
            // 处理特殊情况
            // 熟化
            if (this.cate_code === 'curing') {
                const _pr = params.dict?.[this.cate_code] || '';
                if (_pr !== '') {
                    this.speeds = {'熟化时长': _pr.熟化时长.data};
                }
                return;
            }
            // 分切
            if (this.cate_code === 'split') {
                const _pr = params.dict?.[this.cate_code] || '';
                if (_pr !== '') {
                    this.speeds = {'分切速度': _pr.分切速度.data};
                }
                return;
            }
            // 制袋
            if (this.cate_code === 'mkbag') {
                const _pr = params.dict?.[this.cate_code] || '';
                if (_pr !== '') {
                    this.speeds = {'制袋速度': _pr.制袋速度.data};
                }
                return;
            }
            this.isUnconcernedWithQuantity = true;
            return;
        }
        const validMacs = this.getValidMachines();
        const speedObj = {};
        const props = {};
        for (const [k, v] of Object.entries(processSpeed.data)) {
            props[k] = v.mode;
        }
        let speedRatio = processSpeed.speeds.find((sp) => {
            return this.isMatch(
                Object.keys(props),
                {props, data: sp.props},
                this.getPopertys(Object.keys(props)),
            );
        });
        // 寻找PE代替其进行处理
        if (this.cate_code === 'print' && speedRatio === undefined) {
            speedRatio = processSpeed.speeds.find((sp) => {
                const _testData = this.getPopertys(Object.keys(props));
                _testData.材质 = 'PE';
                return this.isMatch(
                    Object.keys(props),
                    {props, data: sp.props},
                    _testData,
                );
            });
        }
        if (typeof speedRatio !== 'undefined') {
            validMacs.forEach((mac) => {
                speedObj[mac.code] = mac.speed * speedRatio.value / 100;
            });
        }
        this.speeds = speedObj;
    }

    /**
     *
     * @param {string[]} keys
     */
    getPopertys(keys) {
        const _r = {};
        keys.forEach(k => {
            _r[k] = this[k];
        });
        return _r;
    }

    getValidMachines() {
        const _macs = this.machines.map((mac) => {
            const testData = this.getPopertys(Object.keys(mac.data));
            mac.testData = testData;
            mac.isValid = this.isMatch(Object.keys(mac.data), mac, testData);
            return mac;
        });

        let validMacs = _macs.filter((mac) => {
            return mac.isValid;
        });
        if (validMacs.length === 0 && this.cate_code === 'print') {
            // 重新对其进行查询,将其材质设置为PE之后重新查询
            const _macsPE = this.machines.map((mac) => {
                const testData = this.getPopertys(Object.keys(mac.data));
                testData.材质 = 'PE';
                mac.testData = testData;
                mac.isValid = this.isMatch(Object.keys(mac.data), mac, testData);
                return mac;
            });
            validMacs = _macsPE.filter((mac) => {
                return mac.isValid;
            });
        }
        if (validMacs.length > 0) {
            // 保证机台的机速都已经设置
            validMacs = validMacs.filter((mac) => {
                return mac.speed > 0;
            });
            if (validMacs.length === 0) {
                throw new Error(`${this.cate_name}的机台的机速设置都为0,请设置机速`);
            }
        }
        return validMacs;
    }

    getProduceDateCalendar(machineCode, date, direction = 'future') {
        // 工序可用的日历信息
        const cals = this.calendarsMap.get(machineCode);
        return this.getEnabledCalendarDate(cals, dayjs(date), direction);
    }

    /**
     * 获取该工序的可用的日历日期,如果没有可用的日历日期,直接报错
     * @param {machineCalendar} machineCalendar
     * @param {Dayjs} calcStartDate
     * @param {string} [direction='future']
     * @param {number} [maxDays=7] - 最大搜索天数
     * @returns {processCalendar}
     */
    getEnabledCalendarDate(machineCalendar, calcStartDate, direction = 'future', maxDays = 7) {
        let _tmpDate = calcStartDate;
        const multi = direction === 'future' ? 1 : -1;
        let searchedDays = 0;
        let calendar = this.selectCalnedar(_tmpDate, machineCalendar);
        let isCalendarValid = calendar.enabled
        && _tmpDate.diff(calendar.begin, 'minute') >= 0
        && calendar.end.diff(_tmpDate, 'minute') >= 0;
        while (searchedDays < maxDays && !isCalendarValid) {
            // 向下一个日期进行推算意味着已经换天,那么具体时间没有意义
            _tmpDate = _tmpDate.add(1 * multi, 'day');
            _tmpDate.hour(0).minute(0).second(0);
            calendar = this.selectCalnedar(_tmpDate, machineCalendar);
            searchedDays += 1;
            isCalendarValid = calendar.enabled;
        }
        if (searchedDays === maxDays) {
            throw new Error(`没有找到${this.name}的可用的日历日期,请检查日历设置`);
        }
        return calendar;
    }

    /**
     * 现在的工序只有有机台的才会有日历信息,所以如果该工序的不存在机台
     * validMachiens 返回空数组,则需要查找参数信息中的印刷工序的日历信息
     * 或者寻找复合工序的日历信息
     * @returns {calendar[]}
     */
    baseCalender(calendars) {
        // 从配置参数中获取相应的日历数据,已印刷为主,如果没有印刷则寻找复合
        // this.parmCnf.cal
        const printCalendar = calendars.filter(item => item.category.has('print'));
        if (printCalendar.length > 0) {
            return printCalendar;
        }
        const complexCalendar = calendars.filter(item => item.category.has('complex'));
        if (complexCalendar.length > 0) {
            return complexCalendar;
        }
        return [];
    }

    /**
     *
     * @param {calendar[]} cals
     * @returns {calendar|null}
     */
    getMaxStartCalendar(cals) {
        if (cals.length === 0) {
            return null;
        }
        return cals.reduce((acc, item) => {
            if (dayjs(acc.start).isAfter(dayjs(item.start))) {
                return acc;
            } else {
                return item;
            }
        });
    }

    /**
     *
     * @param {Dayjs} date
     * @param {machineCalendar} machineCalendar
     * @returns {processCalendar}
     * @throws {Error}
     */
    selectCalnedar(date, machineCalendar) {
        const tempCalendar = this.getMaxStartCalendar(machineCalendar.calendarList.filter((item) => {
            return item.repeat === 0
            && date.diff(dayjs(item.start), 'day') < item.days.length
            && date.diff(dayjs(item.start), 'day') >= 0;
        }));
        if (tempCalendar) {
            return {
                machineCode: machineCalendar.machineCode,
                'calendarKey': tempCalendar.calendarKey,
                'calendar': tempCalendar,
                date,
                ...this.selectDay(date, tempCalendar),
            };
        }
        // 查询永久循环日历
        const repeatCalendar = this.getMaxStartCalendar(machineCalendar.calendarList.filter(item => {
            return item.repeat === 1 && date.diff(dayjs(item.start), 'day') >= 0;
        }));
        if (repeatCalendar) {
            return {
                machineCode: machineCalendar.machineCode,
                'calendarKey': repeatCalendar.calendarKey,
                'calendar': repeatCalendar,
                date,
                ...this.selectDay(date, repeatCalendar),
            };
        }
        throw new Error(`${this.name}工序给定的日期${date.format('YYYY-MM-DD')}没有找到任何可以匹配的机台日历,请重新设置机台日历信息`);
    }

    /**
     *
     * @param {Dayjs} date
     * @param {calendar} calendar
     */
    selectDay(date, calendar) {
        const _dis = date.diff(dayjs(calendar.start), 'day');
        const day = calendar.days[_dis % calendar.days.length];
        if (day.length !== 2) {
            return {
                begin: null,
                end: null,
                enabled: false,
            };
        }
        const [begin, end] = day.map(item => {
            const [h, m] = item.split(':').map(Number);
            return date.hour(h).minute(m);
        });
        return {
            begin,
            end,
            enabled: true,
        };
    }

    /**
     *
     * @param {string[]} poperkeys
     * @param {matchRule} rule
     * @param {Object} testData
     * @returns {boolean}
     */
    isMatch(poperkeys, rule, testData) {
        return poperkeys.every(propkey => {
            if (rule.props[propkey] === 'contains') {
                return rule.data[propkey].includes(testData[propkey]);
            }
            if (rule.props[propkey] === 'range') {
                // 有两种形式,其中一种为二维数组,另一种为一维数组
                if (isValidTwoDimensionalArray(rule.data[propkey])) {
                    return rule.data[propkey].some(item => {
                        return item[0] <= testData[propkey] && item[1] >= testData[propkey];
                    });
                } else {
                    const start = rule.data[propkey][0];
                    const end = rule.data[propkey][1];
                    return start <= testData[propkey] && end >= testData[propkey];
                }
            }
            if (rule.props[propkey] === 'equal') {
                return rule.data[propkey] === testData[propkey];
            }
            if (rule.props[propkey] === 'lte') {
                return testData[propkey] <= rule.data[propkey];
            }
            return false;
        });
    }

    /**
     * @returns {number} - 准备工时
     */
    get preparationMiunte() {
        return 0;
    }

    get extraProduceMiunte() {
        return this.afterProducedExtraMinutes;
    }

    /** 前序工序间隔时间 */
    get predecessorsGapMiunte() {
        return this.processGapMiuntes;
    }

    /** 后序工序间隔时间 */
    get successorsGapMiunte() {
        return this.processGapMiuntes;
    }

    /**
     *
     * @param {processCalendar} calendar
     * @param {Dayjs} initDate
     * @param {string} [direction='future']
     * @returns {[dayjs, number]} - 日历开始时间,以及可用分钟数
     */
    getCalAvbMinutes(calendar, initDate, direction = 'future') {
        // 判断是否已跳天了
        const isSameDay = initDate.isSame(dayjs(calendar.date), 'day');
        const isDateBetween = calendar.date.isBefore(calendar.end) && calendar.date.isAfter(calendar.begin);
        if (direction === 'future' && isSameDay && isDateBetween) {
            return [initDate, calendar.end.diff(initDate, 'minute'), calendar.end];
        }
        if (direction !== 'future' && isSameDay && isDateBetween) {
            return [calendar.begin, initDate.diff(calendar.begin, 'minute'), initDate];
        }
        return [calendar.begin, calendar.end.diff(calendar.begin, 'minute'), calendar.end];
    }

    /**
     *
     * @param {processQuantity} quantity
     * @param {Dayjs} calcStartDate
     * @returns {EstimateProcessSchedule[]}
     */
    calcScheduleFuture(quantity, calcStartDate) {
        const produceMinutesMap = this.calcProduceMinutes(quantity);
        // 额外生产工时
        const extraMinutes = this.extraProduceMiunte;
        const preparationMinutes = this.preparationMiunte;
        const _arr = Array.from(produceMinutesMap.keys());

        const _schedules = _arr.map((machineCode) => {
            let remainingMinutes = produceMinutesMap.get(machineCode).minutes;
            /** @type {EstimateProcessSchedule} */
            const _schedule = {
                machine_code: machineCode,
                processQuantity: quantity,
                product_name: this.productName,
                process_name: this.name,
                process_cate_name: this.cate_name,
                isEstimate: true,
                producedminutes: produceMinutesMap.get(machineCode).minutes,
                DaysPlan: [],
            };
            let currentDate = dayjs(calcStartDate);
            let firstProduction = true; // 标记是否为第一次生产
            while (remainingMinutes > 0) {
                const machineCalendar = this.getProduceDateCalendar(machineCode, currentDate, 'future');
                // 计算日历的工时
                let [begin, aMinutes] = this.getCalAvbMinutes(machineCalendar, currentDate, 'future');
                let pre = 0;
                if (firstProduction) {
                    aMinutes -= preparationMinutes;
                    pre = preparationMinutes;
                    firstProduction = false;
                } else {
                    pre = 0;
                }
                if (aMinutes <= 0) {
                    currentDate = machineCalendar.begin.add(1, 'day');
                    continue;
                }
                if (aMinutes > remainingMinutes) {
                    const runFinished = begin.add(remainingMinutes + pre, 'minute');
                    _schedule.DaysPlan.push({
                        calendarKey: machineCalendar.calendarKey,
                        date: machineCalendar.date,
                        pre: begin, // 准备时间
                        run: begin.add(pre, 'minute'), // 生产开始时间
                        runFinished,
                        finished: runFinished.add(extraMinutes, 'minute'), // 生产完成时间
                        extraMinutes,
                        begin: machineCalendar.begin, // 日历开始时间
                        end: machineCalendar.end, // 日历结束时间
                        processQuantity: {
                            input: quantity.input * remainingMinutes
                            / produceMinutesMap.get(machineCode).minutes,
                            output: quantity.output * remainingMinutes
                             / produceMinutesMap.get(machineCode).minutes,
                        },
                    });
                    remainingMinutes = 0;
                } else {
                    const runFinished = begin.add(aMinutes + pre, 'minute');
                    _schedule.DaysPlan.push({
                        calendarKey: machineCalendar.calendarKey,
                        date: machineCalendar.date,
                        pre: begin, // 准备时间
                        run: begin.add(pre, 'minute'), // 生产开始时间
                        runFinished,
                        finished: runFinished, // 生产完成时间
                        extraMinutes: 0,
                        begin: machineCalendar.begin, // 日历开始时间
                        end: machineCalendar.end, // 日历结束时间
                        processQuantity: {
                            input: quantity.input * aMinutes / produceMinutesMap.get(machineCode).minutes,
                            output: quantity.output * aMinutes / produceMinutesMap.get(machineCode).minutes,
                        },
                    });
                    remainingMinutes -= aMinutes;
                    currentDate = machineCalendar.begin.add(1, 'day');
                }
            }
            _schedule.begin = _schedule.DaysPlan[0].pre;
            _schedule.end = _schedule.DaysPlan[_schedule.DaysPlan.length - 1].finished;
            return _schedule;
        });
        return _schedules;
    }

    /**
     *
     * @param {processQuantity} quantity
     * @param {Dayjs} calcStartDate
     * @returns {EstimateProcessSchedule[]}
     */
    calcSchedulePost(quantity, calcStartDate) {
        const produceMinutesMap = this.calcProduceMinutes(quantity);
        // 额外生产工时
        const extraMinutes = this.extraProduceMiunte;
        const preparationMinutes = this.preparationMiunte;
        const _arr = Array.from(produceMinutesMap.keys());
        const _schedules = _arr.map((machineCode) => {
            let remainingMinutes = produceMinutesMap.get(machineCode).minutes;
            /** @type {EstimateProcessSchedule} */
            const _schedule = {
                machine_code: machineCode,
                processQuantity: quantity,
                process_name: this.name,
                product_name: this.productName,
                process_cate_name: this.cate_name,
                isEstimate: true,
                producedminutes: produceMinutesMap.get(machineCode).minutes,
                DaysPlan: [],
            };
            const initDate = dayjs(calcStartDate);
            // 重新设置结束的日期
            let currentDate = initDate.subtract(extraMinutes, 'minutes');
            let firstCalc = true; // 标记是否为第一次计算
            while (remainingMinutes > 0) {
                // 寻找第一个可以生产的日历
                const machineCalendar = this.getProduceDateCalendar(machineCode, currentDate, 'post');
                // 计算日历的工时
                const [begin, aMinutes, _end] = this.getCalAvbMinutes(machineCalendar, currentDate, 'post');
                if (aMinutes > remainingMinutes + preparationMinutes) {
                    const runStarted = _end
                        .subtract(remainingMinutes + preparationMinutes, 'minute');
                    _schedule.DaysPlan.push({
                        calendarKey: machineCalendar.calendarKey,
                        date: machineCalendar.date,
                        pre: runStarted.subtract(preparationMinutes, 'minute'), // 准备时间
                        run: runStarted, // 生产开始时间
                        runFinished: _end,
                        finished: firstCalc
                            ? _end.add(extraMinutes, 'minute')
                            : _end, // 完成时间
                        extraMinutes: firstCalc ? extraMinutes : 0,
                        begin: machineCalendar.begin, // 日历开始时间
                        end: machineCalendar.end, // 日历结束时间
                        processQuantity: {
                            input: quantity.input * remainingMinutes
                            / produceMinutesMap.get(machineCode).minutes,
                            output: quantity.output * remainingMinutes
                             / produceMinutesMap.get(machineCode).minutes,
                        },
                    });
                    remainingMinutes = 0;
                } else {
                    const runFinished = begin.add(aMinutes, 'minute');
                    const runStarted = _end.subtract(aMinutes, 'minute');
                    _schedule.DaysPlan.push({
                        calendarKey: machineCalendar.calendarKey,
                        date: machineCalendar.date,
                        pre: runStarted, // 准备时间
                        run: runStarted, // 生产开始时间
                        runFinished: _end,
                        finished: firstCalc
                            ? _end.add(extraMinutes, 'minute')
                            : runFinished, // 完成时间
                        extraMinutes: firstCalc ? extraMinutes : 0,
                        begin: machineCalendar.begin, // 日历开始时间
                        end: machineCalendar.end, // 日历结束时间
                        processQuantity: {
                            input: quantity.input * aMinutes / produceMinutesMap.get(machineCode).minutes,
                            output: quantity.output * aMinutes / produceMinutesMap.get(machineCode).minutes,
                        },
                    });
                    remainingMinutes -= aMinutes;
                    currentDate = machineCalendar.begin.subtract(1, 'day');
                }
                if (firstCalc) {
                    firstCalc = false;
                }
            }
            // 调整daysPlan顺序,保证第一个项目为第一天的起始数据
            _schedule.DaysPlan.reverse();
            // 处理开始时间以及结束时间
            _schedule.begin = _schedule.DaysPlan[0].pre;
            _schedule.end = _schedule.DaysPlan[_schedule.DaysPlan.length - 1].finished;
            return _schedule;
        });
        return _schedules;
    }

    /**
     *
     * @param {processQuantity} quantity
     * @param {Dayjs} calcStartDate
     * @param {'future'|'post'} [direction='future']
     * @returns {EstimateProcessSchedule[]}
     */
    calcSchedule(quantity, date, direction = 'future') {
        if (this.isUnconcernedWithQuantity) {
            return this.calcScheduleWithFixedMinutes(quantity, date, direction);
        }
        if (direction === 'future') {
            return this.calcScheduleFuture(quantity, date);
        } else {
            return this.calcSchedulePost(quantity, date);
        }
    }

    /**
     *
     * @param {processQuantity} quantity
     * @param {Dayjs} calcStartDate
     * @returns {EstimateProcessSchedule[]}
     */
    calcScheduleWithFixedMinutes(quantity, calcStartDate, direction = 'future') {
        const produceMinutesMap = this.calcProduceMinutes(quantity);
        const machineCalendar = this.getProduceDateCalendar(null, calcStartDate, direction);
        return [
            {
                machine_code: null,
                processQuantity: quantity,
                producedminutes: produceMinutesMap.get(null).minutes,
                process_name: this.name,
                product_name: this.productName,
                process_cate_name: this.cate_name,
                isEstimate: true,
                DaysPlan: [{
                    calendarKey: machineCalendar.calendarKey,
                    date: machineCalendar.date,
                    pre: machineCalendar.begin, // 准备时间
                    run: machineCalendar.begin, // 生产开始时间
                    'runFinished': machineCalendar.end,
                    'finished': machineCalendar.end, // 生产完成时间
                    extraMinutes: 0,
                    begin: machineCalendar.begin, // 日历开始时间
                    end: machineCalendar.end, // 日历结束时间
                    processQuantity: {
                        input: quantity.input,
                        output: quantity.output,
                    },
                }],
                begin: machineCalendar.begin,
                end: machineCalendar.end,
            },
        ];
    }
}

/**
 * Class 复合工序
 * @extends Process
 */
class ComplexProcess extends Process {
    constructor(data) {
        super(data);
        this.validateComplexProperties();
    }

    /**
     * 验证基本属性
     * @throws {Error} 抛出错误
     */
    validateComplexProperties() {
        const _title = `产品${this.productName}->${this.name}`;
        if (!glueTypes.includes(this.胶水)) {
            throw new Error(`${_title}<胶水>错误.可选范围:${glueTypes.join('|')},传入的内容为:${this.胶水}`);
        }
        if (!complexTypes.includes(this.类型)) {
            throw new Error(`${_title}<类型>错误,可选范围:${complexTypes.join('|')},传入的内容为:${this.类型}`);
        }
        if (!complexLevels.includes(this.复次)) {
            throw new Error(`${_title}<复次>错误,可选范围:${complexLevels.join('|')},传入的内容为:${this.复次}`);
        }
        if (this.getValidMachines().length === 0) {
            throw new Error(`${this.productName},工序:${this.name}
                类型:${this.类型};胶水:${this.胶水};没有匹配的机台可以生产`);
        }
        // 判断机台速度是否为空
        if (Object.keys(this.speeds).length === 0) {
            throw new Error(`${_title}.
                类型:${this.类型};胶水:${this.胶水};复次:${this.复次},请确认机台速度参数设置`);
        }
        // 处理机台是否都是处于冻结状态
        const isFrozen = this.getValidMachines().every(machine => machine.state === -1);
        if (isFrozen) {
            throw new Error(`${this.productName}工序:${this.name},所有机台均处于冻结状态,无法生产`);
        }
    }

    get preparationMiunte() {
        // 复次*15分钟
        return (complexLevels.indexOf(this.复次) + 1) * 15;
    }

    get extraProduceMiunte() {
        const isLastComplex = this.directSuccessors.every((process) => {
            return process.cate_code !== 'complex';
        });
        const isSuccessorHasCuring = this.directSuccessors.some((process) => {
            return process.cate_code === 'curing';
        });
        const collDownTime = 24 * 60; // 冷却时间
        if (isLastComplex && !isSuccessorHasCuring) {
            switch (this.胶水) {
            case '普通':
                this.afterProducedExtraMinutes = 24 * 60 + collDownTime;
                break;
            case '蒸煮':
                this.afterProducedExtraMinutes = 3 * 24 * 60 + collDownTime;
                break;
            case '水煮':
                this.afterProducedExtraMinutes = 2 * 24 * 60 + collDownTime;
                break;
            default:
                console.error(`胶水类型不存在: ${this.胶水}`);
                this.afterProducedExtraMinutes = 0;
            }
        } else {
            this.afterProducedExtraMinutes = 0;
        }
        return this.afterProducedExtraMinutes;
    }

    /** 前序工序间隔时间 */
    get predecessorsGapMiunte() {
        // 如果其前序是复合工序，则间隔时间为15分钟
        if (this.directPredecessors.some((process) => {
            return process.cate_code === 'complex' && process.isMainPath;
        })) { return 15; }
        return this.processGapMiuntes;
    }

    /** 后序工序间隔时间 */
    get successorsGapMiunte() {
        if (this.directSuccessors.some((process) => {
            return process.cate_code === 'complex' && process.isMainPath;
        })) { return 15; }
        return this.processGapMiuntes;
    }
}
/**
 * @extends Process
 */
class PrintProcess extends Process {
    constructor(data) {
        super(data);
        this.initPreparationMiunte();
        this.validatePrintProperties();
    }

    validatePrintProperties() {
        // 必须有last_porcess且为数组
        if (!Array.isArray(this.色序)) {
            throw new Error(`属性不合法:色序必须为数组`);
        }
        if (!this.材质) {
            throw new Error('缺少属性: 材质');
        }
        if (!printVarnish.includes(this.光油)) {
            throw new Error(`属性<光油>错误.可选范围:${printVarnish.join('|')},传入的内容为:${this.光油}`);
        }
        if (isNaN(this.版宽) || this.版宽 <= 0) {
            throw new Error('属性错误: 版宽 必须为大于0的数');
        }
        if (this.getValidMachines().length === 0) {
            throw new Error(`${this.productName},工序:${this.name}.
                材质:${this.材质};版宽:${this.版宽};色数:${this.色数}
                没有匹配的机台可以生产`);
        }
        // 判断机台速度是否为空
        if (Object.keys(this.speeds).length === 0) {
            throw new Error(`${this.productName},工序:${this.name}.
                材质:${this.材质};色数:${this.色数},请确认机台速度参数设置`);
        }
        // 处理机台是否都是处于冻结状态
        const isFrozen = this.getValidMachines().every(machine => machine.state === -1);
        if (isFrozen) {
            throw new Error(`${this.productName}工序:${this.name},所有机台均处于冻结状态,无法生产`);
        }
    }

    initPreparationMiunte() {
        // 计算印刷工序中的准备时间
        const baseParam = this.parameter.dict.print;
        // 准备工时项目
        const preparationItems = [];
        // 设置时长
        const setupTime = baseParam?.['设置时长']?.data || 0;
        preparationItems.push({'设置时长': setupTime});
        // 光油时长
        if (this.光油 === '有') {
            preparationItems.push({'换光油时长': baseParam?.['光油时长']?.data || 0});
        }
        // 洗胶辊时长
        if (baseParam?.['洗胶辊时长']) {
            preparationItems.push({'洗胶辊时长': this.色数 * (baseParam?.['洗胶辊时长']?.data || 0)});
        }
        // 换版时长
        if (baseParam?.['换版时长']) {
            preparationItems.push({'换版时长': this.色数 * (baseParam?.['换版时长']?.data || 0)});
        }
        // 换色时长
        if (baseParam?.['换色时长']) {
            preparationItems.push({'换色时长': this.色数 * (baseParam?.['换色时长']?.data || 0)});
        }
        // 调色时长
        if (baseParam?.['调色时长']) {
            const noneBaseColors = this.excludeBaseColors(this.色序, baseParam?.['基础色']?.data || []);
            preparationItems.push({'换色时长': noneBaseColors.length * (baseParam?.['调色时长']?.data || 0)});
        }
        this.preparationItems = preparationItems;
    }

    get preparationMiunte() {
        return this.preparationItems.reduce((total, item) => total + Object.values(item)[0], 0);
    }

    /**
     * 从 colors 数组中排除包含 baseColors 中任意颜色的项目
     * 去除 \d+[.-]baseColor
     * @param {string[]} colors - 要过滤的颜色数组
     * @param {string[]} baseColors - 基础颜色数组
     * @returns {string[]} 过滤后的颜色数组
     */
    excludeBaseColors(colors, baseColors) {
        // 如果baseColors为空，则直接返回colors
        if (baseColors.length === 0 || baseColors.every(item => item === '')) return colors;
        // 构建正则表达式
        const regex = new RegExp(`\\d+[-.]?(${baseColors.join('|')})`);
        return colors.filter(color => !regex.test(color));
    }
}
/**
 * @extends Process
 */
class CuringProcess extends Process {
    constructor(data) {
        super(data);
        this.isUnconcernedWithQuantity = true;
    }

    /**
     *
     * @returns {produceMinutesMap}
     */
    calcProduceMinutes() {
        const _pp = new Map();
        _pp.set(null,
            {
                'machineCode': null,
                'minutes': this.speeds['熟化时长'] * 60,
                'speedProp': {
                    'label': '熟化时长',
                    'value': this.speeds['熟化时长'],
                },
            },
        );
        return _pp;
    }

    calcSchedule(quantity, date, direction = 'future') {
        const minutesMap = this.calcProduceMinutes(quantity);
        const multi = direction === 'future' ? 1 : -1;
        const endDate = date.add(multi * minutesMap.get(null).minutes, 'minute');
        /** @type {EstimateProcessSchedule[]} */
        const schedules = [];
        const _begin = direction === 'future' ? date : endDate;
        const _end = direction === 'future' ? endDate : date;
        schedules.push({
            'machineCode': null,
            'begin': _begin,
            'end': _end,
            'processQuantity': quantity,
            'producedminutes': minutesMap.get(null).minutes,
            process_name: this.name,
            product_name: this.productName,
            process_cate_name: this.cate_name,
            isEstimate: true,
            'DaysPlan': [
                {
                    calendarKey: null,
                    date: _begin,
                    pre: _begin, // 准备时间
                    run: _begin, // 生产开始时间
                    runFinished: _end,
                    finished: _end, // 生产完成时间
                    extraMinutes: 0,
                    begin: _begin, // 日历开始时间
                    end: _end, // 日历结束时间
                    processQuantity: quantity,
                },
            ],
        });
        return schedules;
    }

    /** 前序工序间隔时间 熟化工序没有间隔 */
    get predecessorsGapMiunte() {
        return 0;
    }

    /** 后序工序间隔时间 熟化工序冷却24小时 */
    get successorsGapMiunte() {
        return 24 * 60;
    }
}

/**
 * @extends Process
 */
class MkbagProcess extends Process {
    constructor(data) {
        super(data);
        this.validMkagProperties();
    }

    validMkagProperties() {
        if (Object.keys(this.speeds).length === 0) {
            throw new Error(`${this.productName},工序:${this.name}.
                袋型:${this.袋型},请确认工序设置或者机台速度设置`);
        }
    }

    /**
     * TODO 需要单独处理，制袋工序参数完善时再次进行修改
     * @param {processQuantity} [quantity] - 数量参数
     * @returns {produceMinutesMap} - produceMinutesMap
     */
    calcProduceMinutes(quantity) {
        const _pp = new Map();
        Object.keys(this.speeds).forEach((key) => {
            if (this.getValidMachines().length === 0) {
                _pp.set(null,
                    {
                        'machineCode': null,
                        'minutes': quantity.output / this.speeds[key],
                        'speedProp': {
                            'label': key,
                            'value': this.speeds[key],
                        },
                        quantity,
                    },
                );
            } else {
                _pp.set(key, {
                    'machineCode': key,
                    'minutes': quantity.input / this.speeds[key],
                    'speedProp': {
                        'label': key,
                        'value': this.speeds[key],
                    },
                    quantity,
                });
            }
        });
        return _pp;
    }
}

/**
 *
 * @param {Object} processData - 工序数据
 * @returns {Process}
 */
const createProcessHelper = (processData) => {
    switch (processData.process.cate_code) {
    case 'print':
        return new PrintProcess(processData);
    case 'complex':
        return new ComplexProcess(processData);
    case 'curing':
        return new CuringProcess(processData);
    case 'mkbag':
        return new MkbagProcess(processData);
    default:
        return new Process(processData);
    }
};

/**
 * Class 表示产品工序信息 数据结构为图.
 */
class ProductCraftsGraph {
    constructor(product, parmCnf) {
        this.graph = new Map(); // 存储节点及其前序和后序节点
        this.name = product.name;
        this.code = product.code;
        this.craft = product.data['工艺'];
        this.prdInfo = product;
        this.prdInfo.craft = this.craft;
        this.machines = parmCnf.machines;
        this.buildGraph(this.craft.map((process) => createProcessHelper(
            {process, parmCnf, productName: product.name},
        ),
        ));
        // 寻找最短的路径,设置该路径下的的工序为头工序,且该path下都设为主线程
        this.calcMainProcess();
    }

    /**
     * @param {Process} process
     */
    addNode(process) {
        if (!this.graph.has(process.name)) {
            this.graph.set(process.name, {obj: process, predecessors: [], successors: []});
        }
    }

    /**
     * Adds a directed edge between two nodes.
     * @param {string} fromNode - The starting node.
     * @param {string} toNode - The ending node.
     */
    addEdge(fromNode, toNode) {
        if (this.graph.has(fromNode) && this.graph.has(toNode)) {
            this.graph.get(fromNode).successors.push(toNode);
            this.graph.get(toNode).predecessors.push(fromNode);
        } else {
            throw new Error(`Node not found: ${fromNode} or ${toNode}`);
        }
    }

    /**
     * Builds the graph from a list of process class list data.
     * @param {Process[]} ProcessClassList - The process data.
     */
    buildGraph(ProcessClassList) {
        ProcessClassList.forEach(process => {
            this.addNode(process);
        });
        ProcessClassList.forEach(node => {
            node.last_process.forEach(last => {
                this.addEdge(last, node.name);
            });
        });
        // 将所有工序中增加directPredecessors属性指向其前序工序,以及后序工序
        ProcessClassList.forEach(process => {
            process.directPredecessors = process.last_process.map(preProcessName => {
                return this.getProcess(preProcessName);
            });
            process.directSuccessors = this.getDirectSuccessors(process.name)
                .map(successorName => { return this.getProcess(successorName); });
        });
    }

    /**
     * 根据工序名称获取工序对象
     * @param {string} name - 工序名称
     * @returns {Process} 工序
     */
    getProcess(name) {
        const node = this.graph.get(name);
        if (!node) {
            throw new Error(`工序不存在: ${name}`);
        }
        return node.obj;
    }

    /**
     * 获取头工序,保证主工序排在第一位
     * @returns {Process[]} 头工序列表.
     */
    getStartingProcesses() {
        const headPorcess = [...this.graph.values()]
            .filter(node => node.predecessors.length === 0)
            .map(node => node.obj);
        headPorcess.sort((a, b) => (a.isMainHead ? 1 : -1) - (b.isMainHead ? 1 : -1));
        return headPorcess;
    }

    /**
     * @returns {Process} 尾工序
     * @throws 如果有多个尾工序抛出错误
     */
    getEndingProcess() {
        const endingNodes = [...this.graph.values()].filter(node => node.successors.length === 0);
        if (endingNodes.length !== 1) {
            throw new Error('尾工序数量错误');
        }
        return endingNodes[0].obj;
    }

    /**
     *
     * @param {string} processName
     * @param {string} direction -predecessors|successors
     * @returns {string[][]} -processName->next->next
     */
    findPaths(processName, direction = 'predecessors') {
        const result = [];
        const dfs = (currentNode, path) => {
            path.push(currentNode);
            const neighbors = this.graph.get(currentNode)[direction];
            if (neighbors.length === 0) {
                result.push([...path].reverse());
            } else {
                neighbors.forEach(neighbor => dfs(neighbor, path));
            }
            path.pop();
        };
        dfs(processName, []);
        // if (direction === 'successors') {
        //     result.forEach(path => path.reverse());
        // }
        result.forEach(path => path.reverse());
        return result;
    }

    /**
     * 重新计算各个工艺路线是否为主路线
     * 规则1:线路最短的为主路线
     * 规则2:如果线路一样长,则排在第一位的为主路线
     */
    calcMainProcess() {
        const mainPath = this.fullPath().reduce((acc, cur) => {
            return acc.length <= cur.length ? acc : cur;
        });
        for (let i = 0; i < mainPath.length; i++) {
            this.getProcess(mainPath[i]).isMainPath = true;
            if (i === mainPath.length - 1) {
                this.getProcess(mainPath[i]).isMainHead = true;
            }
        }
    }

    /**
     * 给出全部的路线图
     * @returns {string[][]} 路线图 [end->pre->pre...start]
     */
    fullPath() {
        const endName = this.getEndingProcess().name;
        return this.findPrecedingProcesses(endName);
    }

    /**
     * 查找指定进程名之前的所有进程
     * @param processName 进程名
     * @returns {string[][]} 返回指定进程名之前的所有进程的路径
    */
    findPrecedingProcesses(processName) {
        return this.findPaths(processName, 'predecessors');
    }

    /**
     * 查找指定进程名之后的所有进程
     * @param processName 进程名
     * @returns {string[][]} 返回指定进程名之前的所有进程的路径
    */
    findSucceedingProcesses(processName) {
        return this.findPaths(processName, 'successors');
    }

    calculateDepths() {
        const depths = new Map();
        const visited = new Set();

        const dfs = (node, depth) => {
            if (depths.has(node)) {
                depths.set(node, Math.max(depths.get(node), depth));
            } else {
                depths.set(node, depth);
            }
            visited.add(node);

            this.graph.get(node).successors.forEach(successor => {
                if (!visited.has(successor)) {
                    dfs(successor, depth + 1);
                }
            });
        };

        this.getStartingProcesses().forEach(process => {
            dfs(process.name, 0);
        });

        return depths;
    }

    /**
     * 获取指定工序的直属前序工序
     * @param {string} processName - 工序名称
     * @returns {string[]} 直属前序工序的名称列表
     */
    getDirectPredecessors(processName) {
        const node = this.graph.get(processName);
        if (!node) {
            console.error(`工序不存在: ${processName}`);
        }
        return node.predecessors;
    }

    /**
     * 获取指定工序的直属后序工序
     * @param {string} processName - 工序名称
     * @returns {string[]} 直属后序工序的名称列表
     */
    getDirectSuccessors(processName) {
        const node = this.graph.get(processName);
        if (!node) {
            console.error(`工序不存在: ${processName}`);
            return [];
        }
        return node.successors;
    }

    /**
     * 根据指定的 cate_code 筛选节点
     * @param {string} cateCode - 要筛选的 cate_code
     * @returns {Process[]} 符合条件的工序对象列表
     */
    filterNodesByCateCode(cateCode) {
        const filteredNodes = [];

        // 遍历图中所有的节点
        this.graph.forEach((node) => {
            if (node.obj.cate_code === cateCode) {
                filteredNodes.push(node.obj);
            }
        });

        return filteredNodes;
    }

    /**
     * TODO：制袋前的工序如果是分切，那么制袋的投入米数怎么计算？按照印刷的投入量？
     * 根据尾工序的产出量推算出各个工序的产出量和投入量
     * @param {number} finalOutput - 尾工序的产出量
     * @returns {Map<string, {output: number, input: number}>} 每个工序的产出量和投入量
     */
    calculateProcessOutputsAndInputs(finalOutput) {
        const outputInputMap = new Map();
        // 按照工序的路线进行推算
        // 获取尾工序的节点
        const endProcess = this.getEndingProcess();
        if (!endProcess) {
            throw new Error(`${this.name}的尾工序不存在`);
        }
        // 初始化尾工序的产出量
        outputInputMap.set(endProcess.name, {
            output: finalOutput,
        });

        // 定义递归函数计算前序工序的投入和产出
        const calculateOutput = (process) => {
            const currentOut = outputInputMap.get(process.name).output;
            // 前序
            const preProcessList = this.getDirectPredecessors(process.name).map(n => this.getProcess(n));
            // 计算前工序的产出量
            preProcessList.forEach(preProcess => {
                if (!outputInputMap.has(preProcess.name)) {
                    outputInputMap.set(preProcess.name, {
                        output: currentOut * preProcess.output_ratio / process.output_ratio,
                    });
                }
                calculateOutput(preProcess);
            });
        };

        // 从尾工序开始反向推导所有前序工序的产出和投入
        calculateOutput(endProcess);
        // 根据结果计算各个工序的投入量,从尾工序开始其投入量为前工序的产出量
        const calculateInput = (process) => {
            const preProcessList = this.getDirectPredecessors(process.name).map(n => this.getProcess(n));
            const mainPreProcessList = preProcessList.filter(p => p.isMainPath);
            const mainPreProcess = mainPreProcessList.length > 0 ? mainPreProcessList[0] : preProcessList[0];
            if (!mainPreProcess) {
                // 首工序处理
                // 只有一道工序的处理
                if (process.name === endProcess.name) {
                    outputInputMap.get(process.name).input =
                    outputInputMap.get(process.name).output;
                } else {
                    // 尾工序的产出量/尾工序的output_ratio*首工序的output_ratio
                    outputInputMap.get(process.name).input = outputInputMap.get(endProcess.name).output
                    / endProcess.output_ratio * process.output_ratio;
                }
            } else {
                outputInputMap.get(process.name).input = outputInputMap.get(mainPreProcess.name).output;
                preProcessList.forEach(preProcess => {
                    calculateInput(preProcess);
                });
            }
        };
        calculateInput(endProcess);
        return outputInputMap;
    }

    /**
     * 计算工艺路线的排产时间
     * @param {Dayjs|string} endDate - 排产结束日期
     * @param {number} finalOutput - 尾工序的产出量
     * @param {string} [direction='future'] 计算方向
     * @param {Map<string, EstimateProcessSchedule>} [resultHolder] 结果保存对象
     * @returns {Map<string, EstimateProcessSchedule[]>} 工序的排产时间 <process.name, AllProcessSchedule>
     */
    calculateSchedule(endDate, finalOutput, direction = 'future', resultHolder) {
        const result = resultHolder || new Map();
        const inputOutputMap = this.calculateProcessOutputsAndInputs(finalOutput);

        if (direction !== 'future') {
            const endProccess = this.getEndingProcess();
            this.calcSchPost(endProccess, inputOutputMap, dayjs(endDate), result);
        }
        if (direction === 'future') {
            this.getStartingProcesses().forEach(process => {
                this.calcSchFuture(process, inputOutputMap, dayjs(endDate), result);
            });
        }
        result.values().forEach(sch => {
            const _m = this.machines.find(m =>
                m.name === sch.machine_code || m.code === sch.machine_code);
            sch.machine_name = _m ? _m.name : '';
        });
        return result;
    }

    /**
     * 根据传入的排产结果计算工艺路线的排产时间
     * @param {Map<string, EstimateProcessSchedule>} resultHolder 已经计算好的排产结果 <process.name,sch>
     * @returns {Map<string, EstimateProcessSchedule>|null} 工序的排产时间 <process.name, sch>
     */
    calcWithScheduleResult(resultHolder) {
        /** @type {EstimateProcessSchedule[]} */
        const schdList = [...resultHolder.values()];
        // 获取产出
        if (schdList.length === 0) return;
        const result = new Map(resultHolder);
        const inputOutputMap = this.calculateProcessOutputsAndInputs(schdList[0].order_finalOutput);
        schdList.forEach(sch => {
            const process = this.getProcess(sch.process_name);
            this.calcSchPost(process, inputOutputMap, sch.begin, result);
            this.calcSchFuture(process, inputOutputMap, sch.end, result);
        });
        result.values().forEach(sch => {
            const _m = this.machines.find(m =>
                m.name === sch.machine_code || m.code === sch.machine_code);
            sch.machine_name = _m ? _m.name : '';
        });
        return result;
    }

    /**
     *
     * @param {Process} process
     * @param {Map} inputOutputMap
     * @param {Dayjs} endDate
     * @param {Map} result
     * @param {string} rule - FFT: Faster Finished Time 尽快完成, LFT: 最迟完成
     */
    calcSchPost(process, inputOutputMap, endDate, result, rule = 'FFT') {
        if (!result.has(process.name)) {
            const schList = process.calcSchedule(inputOutputMap.get(process.name), endDate, 'post');
            const sch = this.filterSch(schList, rule);
            result.set(process.name, sch);
        }
        const _sch = result.get(process.name);
        // 计算前工序的排产时间
        process.directPredecessors.forEach(preProcess => {
            // 处理endDate
            const preEndDate = _sch.begin.subtract(preProcess.successorsGapMiunte, 'minutes');
            this.calcSchPost(preProcess, inputOutputMap, preEndDate, result, rule);
        });
    }

    /**
     *
     * @param {Process} process
     * @param {Map} inputOutputMap
     * @param {Dayjs} beginDate
     * @param {Map} result
     * @param {string} rule - FFT: Faster Finished Time 尽快完成, LFT: 最迟完成
     */
    calcSchFuture(process, inputOutputMap, beginDate, result, rule = 'FFT') {
        // 判断改工序是否有多个前工序,保证前工序的所有的排产已计算,否则进行等待
        let _beginDate = beginDate;
        if (process.directPredecessors.length > 1) {
            const isPreAllCalecd = process.directPredecessors.every(preProcess => {
                return result.has(preProcess.name);
            });
            if (!isPreAllCalecd) {
                // 等待前工序计算完成
                process.directPredecessors.forEach(preProcess => {
                    if (!result.has(preProcess.name)) {
                        this.calcSchPost(preProcess, inputOutputMap, beginDate, result, rule);
                    }
                });
            }
            // 获取前序的最大的完成日期
            _beginDate = process.directPredecessors.map(preProcess => {
                return result.get(preProcess.name).end;
            }).reduce((acc, cur) => {
                return acc.isAfter(cur) ? acc : cur;
            });
            _beginDate = _beginDate.add(process.predecessorsGapMiunte, 'minute');
        }
        if (!result.has(process.name)) {
            const schList = process.calcSchedule(inputOutputMap.get(process.name), _beginDate, 'future');
            const sch = this.filterSch(schList, rule);
            result.set(process.name, sch);
        }
        const _sch = result.get(process.name);
        // 计算后工序的排产时间
        process.directSuccessors.forEach(succProcess => {
            // 处理beginDate
            const succBeginDate = _sch.end.add(process.successorsGapMiunte, 'minutes');
            this.calcSchFuture(succProcess, inputOutputMap, succBeginDate, result, rule);
        });
    }

    /**
     * @param {EstimateProcessSchedule[]} schList
     * @param {string} rule - FFT: Faster Finished Time 尽快完成, LFT: 最迟完成
     * @returns {EstimateProcessSchedule}
     */
    filterSch(schList, rule = 'FFT') {
        if (schList.length === 1) {
            return schList[0];
        }
        if (rule === 'FFT') {
            // 筛选最早完成日期的排产结果
            return schList.reduce((maxSchedule, currentSchedule) =>
                currentSchedule.end.isBefore(maxSchedule.end) ? currentSchedule : maxSchedule,
            );
        }
        return schList.reduce((maxSchedule, currentSchedule) =>
            currentSchedule.end.isAfter(maxSchedule.end) ? currentSchedule : maxSchedule,
        );
    }

    /**
     * 打印工序的工艺路线
     */
    printGraph() {
        this.fullPath().forEach(path => {
            console.log(path.join('-->'));
        });
    }
}

/**
 *
 * @param {string[]} productNameList
 * @returns {Promise<ProductCraftsGraph[]>} 返回产品的工艺的路线图
 */
const buildProductCraftsGraphList = async (productNameList) => {
    const pramCnf = await getParamConfig();
    const productList = await getProductsWithSpeed(productNameList);
    return productList.map(product => {
        const _pcg = new ProductCraftsGraph(product, pramCnf);
        return _pcg;
    });
};

/**
 *
 * @returns {Promise<Parameter>}
 */
const getPrimeParams = async () => {
    const url = '/api/parameters';
    const response = await axios.get(url);
    const baseData = response.data.data.map(item => {
        return {
            name: item.defined.name,
            data: item.data,
            type: item.defined.type,
            category: item.defined.category.code,
        };
    });
    // 工时参数处理
    const workHours = baseData.filter(item => item.name.endsWith('工时')).reduce((acc, cur) => {
        acc[cur.category] = cur.data;
        return acc;
    }, {});
    // 处理参数
    return {
        workHours,
        baseList: baseData,
        dict: baseData.reduce((acc, cur) => {
            if (Object.hasOwn(acc, cur.category)) {
                acc[cur.category][cur.name] = cur;
            } else {
                acc[cur.category] = {
                    [cur.name]: cur,
                };
            }
            return acc;
        }, {}),
    };
    // 按照工序进行分组处理
};

/**
 *
 * @returns {Promise<Speed[]>}
 */
const getSpeedsParam = async () => {
    const url = '/api/speeds';
    const response = await axios.get(url);
    return response.data.data;
};

/**
 *
 * @returns {Promise<machine[]>}
 */
const fetchMachiesWithParam = async () => {
    const url = '/api/machines';
    const response = await axios.get(url);
    const machineParam = (await axios.get('/api/parameter-defineds/?t=2')).data.data;
    // 获取日历信息
    const machines = response.data.data;
    machines.forEach(item => {
        // 机台的参数信息
        const mps = machineParam.filter(param => param.category.code === item.category.code);
        item['props'] = mps.reduce((acc, cur) => {
            acc[cur.name] = cur.match;
            return acc;
        }, {});
    });
    return machines.filter(item => item.state === 1);
};
/**
 * 获取预排产的机台的信息
 * @param {Craft} craft
 * @param {machine[]} machines
 */
const getPreSchedulingPrintMachines = (craft, machines) => {
    if (!craft || craft.cate_name !== '印刷') return [];
    /**
     *
     * @param {machine} mac
     * @param {Craft} craft
     */
    function isMatch(mac, craft) {
        const poperkeys = Object.keys(mac.data);
        return poperkeys.every(propkey => {
            if (mac.props[propkey] === 'contains') {
                return mac.data[propkey].includes(craft[propkey]);
            }
            if (mac.props[propkey] === 'range') {
                // 有两种形式,其中一种为二维数组,另一种为一维数组
                if (isValidTwoDimensionalArray(mac.data[propkey])) {
                    return mac.data[propkey].some(item => {
                        return item[0] <= craft[propkey] && item[1] >= craft[propkey];
                    });
                } else {
                    const start = mac.data[propkey][0];
                    const end = mac.data[propkey][1];
                    return start <= craft[propkey] && end >= craft[propkey];
                }
            }
            if (mac.props[propkey] === 'equal') {
                return mac.data[propkey] === craft[propkey];
            }
            if (mac.props[propkey] === 'lte') {
                return craft[propkey] <= mac.data[propkey];
            }
            return false;
        });
    }
    const _macs = machines.map((mac) => {
        mac.isValid = isMatch(mac, craft);
        return mac;
    });
    let validMacs = _macs.filter((mac) => {
        return mac.isValid;
    });
    if (validMacs.length === 0) {
        // 重新对其进行查询,将其材质设置为PE之后重新查询
        const _tm = {...craft};
        _tm.材质 = 'PE';
        const _macsPE = machines.map((mac) => {
            mac.isValid = isMatch(mac, _tm);
            return mac;
        });
        validMacs = _macsPE.filter((mac) => {
            return mac.isValid;
        });
    }
    return validMacs.map((m) => { return {code: m.code, name: m.name, selected: false}; });
};
/**
 *
 * @returns {Promise<ParamCnf>}
 */
const getParamConfig = async () => {
    const params = await getPrimeParams();
    // 获取速度参数
    const speeds = await getSpeedsParam();
    // 获取机器列表
    const machines = await fetchMachiesWithParam();
    const calendarList = (await axios.get('/api/calendar')).data.data;
    /** @type {calendar} */
    const calendar = calendarList.reduce((acc, cur) => {
        const {name, start, repeat, days, mach_id} = cur;
        const calendarKey = `${name}-${start}-${repeat}`;
        const _acc = acc.filter(item => item.calendarKey === calendarKey);
        if (_acc.length === 0) {
            acc.push({
                calendarKey,
                name,
                start,
                repeat,
                days,
                machineCodes: new Set(machines.filter(item => item.id === mach_id).map(item => item.code)),
                category: new Set(machines.filter(item => item.id === mach_id)
                    .map(item => item.category.code)),
            });
        } else {
            // 防止重复添加
            _acc[0].machineCodes.add(machines.filter(item => item.id === mach_id)[0].code);
            _acc[0].category.add(machines.filter(item => item.id === mach_id)[0].category.code);
        }
        return acc;
    }, []);
    return {
        params,
        speeds,
        machines,
        calendar,
    };
};
/**
 *
 * @returns {Promise<ProductInfo[]>}
 */
const getProductsWithSpeed = async (productlist) => {
    const localProducts = [...new Set(productlist)];
    if (localProducts.length === 0) return;
    const fetchProductsPromise = [];
    // const gsize = 25;
    const serveMaxLength = 2000;
    const prdGroups = localProducts.reduce((acc, cur, index) => {
        if (index === 0) {
            acc.push([cur]);
            return acc;
        }
        const _textSize = encodeURI(cur).length
              + encodeURI(acc[acc.length - 1].join(',')).length;
        if (_textSize >= serveMaxLength) {
            acc.push([cur]);
        } else {
            acc[acc.length - 1].push(cur);
        }
        return acc;
    }, []);
    prdGroups.forEach((prds) => {
        fetchProductsPromise.push(
            fetchProducts({'products': prds.join(',')}));
    });
    // 使用 Promise.all 获取所有产品数据
    const results = await Promise.all(fetchProductsPromise);

    // 汇总结果并返回
    const allProducts = results.reduce((acc, data) => {
        if (data && Array.isArray(data.data)) {
            acc.push(...data.data);
        }
        return acc;
    }, []);
    return allProducts;
};
/**
 *
 * @returns {Promise<ProductInfo[]>}
 */
const fetchProducts = async (args) => {
    const url = `/api/products/`;
    const params = {'withspeeds': false};
    if (args.products) {
        params.q = args.products;
    }
    try {
        const res = await axios.get(url, {params});
        if (res.data.error) {
            throw new Error(res.data.error);
        }
        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            // 处理 Axios 错误
            console.error('Axios Error:', error.message);
        }
        throw error;
    }
};

/**
 * 计算全流程排产
 * @param {ScheduleResult[]} schdList - 已计算好的排产结果
 * @param {JobResult[]} jobList - job列表
 * @param {OrderResult[]} orderList - 订单列表
 * @returns {Promise<ScheduleResult[]>}
 */
const estimateSchBasedOnActuaryRes = async (schdList, jobList, orderList) => {
    const fmt = 'YYYY-MM-DD HH:mm:ss';
    // 构建产品路线图
    const prdGraphList = await buildProductCraftsGraphList(schdList.map(item => item.product));
    // 构建排产订单任务信息获取最终产出量
    /** @type {EstimateProcessSchedule[]} 已排产信息 */
    const toEsSchArray = [];
    for (let i = 0; i < schdList.length; i++) {
        const schd = schdList[i];
        const job = jobList.find(item => item.job_code === schd.job_code);
        if (!job) continue;
        const order = orderList.find(item => item.order_code === job.order_code);
        if (!order) continue;
        const _shcdRes = toEsSchArray.find(item => item.job_code === schd.job_code);
        const schdDatePre = dayjs(`${schd.date} ${schd.pre}`).isValid()
            ? dayjs(`${schd.date} ${schd.pre}`)
            : dayjs(`${schd.date}`);
        const schdDateRun = dayjs(`${schd.date} ${schd.run}`).isValid()
            ? dayjs(`${schd.date} ${schd.run}`)
            : dayjs(`${schd.date}`);
        const schdDateEnd = dayjs(`${schd.date} ${schd.end}`).isValid()
            ? dayjs(`${schd.date} ${schd.end}`)
            : dayjs(`${schd.date}`);
        if (!_shcdRes) {
            toEsSchArray.push({
                'job_code': schd.job_code,
                'product_name': schd?.product_info?.name || schd.product,
                'order_code': order.order_code,
                'process_name': schd.process_name,
                'process_cate_name': schd.process_cate_name,
                'order_finalOutput': order.quantity,
                'begin': schdDatePre,
                'end': schdDateEnd,
                machine_code: schd.machine,
                isEstimate: false,
                task_id: order?.task_id || null,
                'processQuantity': {'input': job.quantity, 'output': job.quantity},
                'machineCode': schd.machine_info.code,
                'producedminutes': schdDateEnd.diff(schdDateRun, 'minutes'),
                'DaysPlan': [
                    {
                        'pre': schdDatePre,
                        'run': schdDateRun,
                        'runFinished': schdDateEnd,
                        'extraMinutes': 0,
                        'finished': schdDateEnd,
                        'processQuantity': {'input': schd.quantity, 'output': schd.quantity},
                        'calendarKey': null,
                    },
                ],
            });
        } else {
            _shcdRes.DaysPlan.push({
                'pre': schdDatePre,
                'run': schdDateRun,
                'runFinished': schdDateEnd,
                'extraMinutes': 0,
                'finished': schdDateEnd,
                'processQuantity': {'input': schd.quantity, 'output': schd.quantity},
                'calendarKey': null,
            });
            _shcdRes.begin = schdDatePre.isBefore(_shcdRes.begin) ? schdDatePre : _shcdRes.begin;
            _shcdRes.end = schdDateEnd.isAfter(_shcdRes.end) ? schdDateEnd : _shcdRes.end;
        }
    }
    // 对schedList按照order_code进行分组
    /** @type {Map<string,EstimateProcessSchedule[]>} */
    const schdMap = new Map();
    toEsSchArray.forEach(item => {
        const key = item.order_code;
        if (!schdMap.has(key)) {
            schdMap.set(key, []);
        }
        schdMap.get(key).push(item);
    });
    /** @type {ScheduleResult[]} */
    const allSchdReas = [];
    schdMap.forEach((_schdList, orderCode) => {
        // 按照订单处理各个全流程
        // 获取产品名称获得改产品的工艺构造器
        if (_schdList.length === 0) return;
        const prdName = _schdList[0].product_name;
        const task_id = _schdList[0].task_id;
        const _prdG = prdGraphList.find(item => item.name === prdName);
        if (!_prdG) return;
        const _schedMap = new Map(_schdList.map(item => [item.process_name, item]));
        const result = _prdG.calcWithScheduleResult(_schedMap);
        // 保存并转换计算的排产结果,并排除,并给定相应的job_code
        // 如何计算job_code,按照订单号以及排产结果的size进行生成相应的序号,排除已存在的job_code
        // 处理job_code
        const _cl = [];
        result.forEach(item => { _cl.push(item?.job_code || '_'); });
        result.forEach(item => {
            // 处理任务单号信息
            if (!Object.hasOwn(item, 'job_code')) {
                let _f = true;
                let _i = 1;
                while (_f) {
                    const _j = `${orderCode}-${_i}`;
                    if (_cl.indexOf(_j) === -1) {
                        _cl.push(_j);
                        _f = false;
                        item.job_code = _j;
                    }
                    _i += 1;
                }
            }
        });
        // 转换排产,排除精算的排产结果
        result.forEach(item => {
            if (item.isEstimate) {
                item.DaysPlan.forEach(day => {
                    allSchdReas.push({
                        job_code: item.job_code,
                        machine: item.machine_code,
                        machine_name: item.machine_name,
                        product: item.product_name,
                        quantity: day.processQuantity.output,
                        pre: day.pre.format(fmt),
                        run: day.run.format(fmt),
                        end: day.runFinished.format(fmt),
                        date: day.pre.format('YYYY-MM-DD'),
                        process_name: item.process_name,
                        process_cate_name: item.process_cate_name,
                        remark: `全流程推算,单号:${orderCode}`,
                        order_code: orderCode,
                        task_id,
                        product_info: _prdG.prdInfo,
                    });
                });
            }
        });
    });
    return allSchdReas;
};

/**
 * 预估产品工期
 * @param {string} product_name - 产品名称
 * @param {string|dayjs} shipment_date - 交货期 datestring
 * @param {quantity} quantity - 成品数量
 * @param {string} direction - 计算方向
 * @returns {Promise<[array,Map<string,EstimateProcessSchedule>]>}
 * [工艺,process.name => EstimateProcessSchedule]
 */
const estimateProductPlan = async (product_name, shipment_date, quantity, direction = 'future') => {
    const prdGraphList = await buildProductCraftsGraphList([product_name]);
    if (prdGraphList.length === 0) return;
    const prdGraph = prdGraphList[0];
    const endDate = dayjs(shipment_date);
    const esMap = prdGraph.calculateSchedule(endDate, quantity, direction);
    return [prdGraph.craft, esMap];
};
export default {
    buildProductCraftsGraphList,
    getParamConfig,
    getProductsWithSpeed,
    estimateSchBasedOnActuaryRes,
    estimateProductPlan,
    getPreSchedulingPrintMachines,
    fetchMachiesWithParam,
};

export {
    buildProductCraftsGraphList,
    getParamConfig,
    getProductsWithSpeed,
    estimateSchBasedOnActuaryRes,
    estimateProductPlan,
    getPreSchedulingPrintMachines,
    fetchMachiesWithParam,
};
