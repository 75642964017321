<template>
    <el-dialog
        :model-value="localVisible"
        title="选择预排产机台"
        width="45%"
        @close="closeDialog">
        <el-radio-group v-model="selectedMachineId">
            <el-radio
                v-for="machine in machines"
                :key="machine.code"
                border
                :value="machine.code">
                {{ machine.name }}
            </el-radio>
        </el-radio-group>
        <template #footer>
            <el-button @click="closeDialog">取消</el-button>
            <el-button type="primary" @click="saveSelection">保存</el-button>
        </template>
    </el-dialog>
</template>

<script setup lang="jsx">
import {ref, watch, defineProps, defineEmits} from 'vue';
import {ElMessage} from 'element-plus';

// Props 和 Emits 定义
const props = defineProps({
    visible: Boolean,
    machines: {type: Array, default: null},
});
const emit = defineEmits(['save', 'update:visible']);

// 本地的 dialog 可见状态
const localVisible = ref(props.visible);

// 监听 `props.visible`，同步到 `localVisible`
watch(
    () => props.visible,
    (newVal) => {
        localVisible.value = newVal;
    },
);

// 当前选中的机台 ID
const selectedMachineId = ref(null);

// 监听 `machines` 数据，并初始化选中的机台
watch(
    () => props.machines,
    (machines) => {
        const selected = machines.find((machine) => machine.selected);
        selectedMachineId.value = selected ? selected.code : null;
    },
    {immediate: true},
);
console.log(props.machines);
// 关闭弹窗
const closeDialog = () => {
    localVisible.value = false;
    emit('update:visible', false);
};

// 保存选择的机台
const saveSelection = () => {
    if (!selectedMachineId.value) {
        ElMessage.error('请选择一个机台！');
        return;
    }
    emit('save', selectedMachineId.value);
    closeDialog();
};
</script>

  <style scoped>
  .el-dialog {
    text-align: center;
  }
  </style>
