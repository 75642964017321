<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
    <el-row class="query-row">
        <el-col :span="3">
            <el-input
                v-model="keywords.order_code"
                autocomplete="off"
                placeholder="订单号"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="3">
            <el-input
                v-model="keywords.product"
                autocomplete="off"
                placeholder="产品"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="2">
            <el-select
                v-model="keywords.isError"
                autocomplete="off"
                clearable
                placeholder="错误过滤">
                <el-option label="" value="" />
                <el-option label="无错误" value="0" />
                <el-option label="有错误" value="1" />
            </el-select>
        </el-col>
        <el-col :span="3">
            <div class="date-range">
                <el-date-picker
                    v-model="keywords.shipment_date"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="交货期"
                    end-placeholder=""
                    size="default"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD" />
            </div>
        </el-col>
        <el-col :span="2" />
        <el-col :span="2" />
    </el-row>
    <el-row class="table-container">
        <ListTable
            ref="vTableRef" :records="orderList" :options="vtableOptions"
            @onClickCell="handleClickCell"
            @onDropdownMenuClick="handleDropdownMenuClick"
            @onMouseEnterCell="handleMouseEnterCell">
            <ListColumn
                :field="'checked'" :title="''" cell-type="checkbox"
                header-type="checkbox" :width="40"
                :style="{size:16,
                         checkboxStyle:{checkedFill:'#409eff',defaultFill:'#ffffff'}}" />
            <ListColumn
                :title="'序号'" :field-format="(rec,col,row)=>row"
                :disable-hover="true"
                cursor="pointer"
                :width="60"
                :header-style="{fontSize:14}"
                :style="{bgColor:args=>calcErrorColor(args),fontSize:12}" />
            <ListColumn :field="'order_code'" :title="'订单号'" :width="120" />
            <ListColumn
                :field="'product'" :title="'产品'" :width="400"
                :icon="prdSyncIcon" />
            <ListColumn :field="'quantity'" :title="'数量'" :style="{textAlign:'right'}" :width="80" />
            <ListColumn
                :field="'is_finished'" :title="'完成'" width="80">
                <template #customLayout="{record, height, width }">
                    <Group
                        :height="height"
                        :width="width"
                        display="flex"
                        flex-direction="row"
                        align-items="center"
                        justify-content="center">
                        <Group
                            :height="height*0.6"
                            :width="width*0.8"
                            display="flex"
                            :fill="record.is_finished!=='是'?'#13ce66':'#ff4949'"
                            :corner-radius="10"
                            :flex-direction="record.is_finished!=='是'?'row':'row-reverse'"
                            align-items="center"
                            justify-content="space-around"
                            cursor="pointer"
                            style="padding:0 10px;"
                            @mousedown="switchFinishedState($event,record)">
                            <Text
                                :font-size="14"
                                fill="#ffffff"
                                :text="record.is_finished" />
                            <Group
                                :height="20"
                                :width="20"
                                :corner-radius="20"
                                fill="white" />
                        </Group>
                    </Group>
                </template>
            </ListColumn>
            <ListColumn
                :show-sort="true"
                :sort="onSort"
                :field="'shipment_date'" :title="'计划交期'" :width="135" />
            <ListColumn
                :field="'remark'"
                :title="'备注'" :width="120" :style="{autoWrapText:true}" />
            <ListColumn
                :field="'op'" :width="100"
                :header-icon="opHeaderIcon">
                <template #customLayout="{record, height, width }">
                    <Group
                        :height="height"
                        :width="width"
                        display="flex"
                        flex-direction="row"
                        align-items="center"
                        justify-content="space-around">
                        <Image
                            :width="20"
                            :height="20"
                            :image="sEdit"
                            cursor="pointer" />
                        <Image
                            :width="20"
                            :height="20" :image="addJobImage(record)"
                            :cursor="cursorState(record)" />
                        <Image
                            :width="20" :height="20"
                            :image="sDelete" cursor="pointer" />
                    </Group>
                </template>
            </ListColumn>
        </ListTable>
    </el-row>
    <order-list-edit ref="refOrderEdit" />
    <JobSplitOpt
        :dialog-visible="showJobSplit" :jobs="jobInfo"
        :order-info="orderInfo" @close="showJobSplit=false" />
    <productOpt ref="refProductOpt" />
</template>
<script setup>
import SchdStore from '../stores/schdtabular.js';
import {notify} from '../assets/js/utils.js';
import {getErpPrd, getPrdInfo} from '../assets/js/erp2Schd.js';
import {autoTabluar} from '../assets/js/schd.js';
import {estimateProductPlan} from '../assets/js/allSchdCalc.js';
import {ListTable, ListColumn, Group, Text, Image} from '@visactor/vue-vtable';
import * as VTable from '@visactor/vtable';
import {computed, ref, defineProps, onBeforeMount, watch} from 'vue';
import dayjs from 'dayjs';
import OrderListEdit from './order-list-edit.vue';
import JobSplitOpt from './job-split-opt.vue';
import productOpt from './product-opt.vue';
import {
    editIcon, refreshIcon, questionError,
    plusIcon, generateSVG,
    deleteIcon,
} from '../assets/js/vTableCustomIcons.js';
import {ElMessageBox} from 'element-plus';

// #region 加载svg
const sEdit = ref('');
const sOp = ref('');
const sOpDisable = ref('');
const sDelete = ref('');
onBeforeMount(() => {
    sEdit.value = generateSVG('edit', 'rgb(64, 158, 255)');
    sOpDisable.value = generateSVG('operation', '#a0cfff');
    sOp.value = generateSVG('operation', 'rgb(64, 158, 255');
    sDelete.value = generateSVG('delete', 'rgb(255, 77, 79)');
});
// #endregion
// #region vue 初始化
// 加载pinia
const uWbStore = SchdStore();
// 搜索关键词
const keywords = ref({
    'order_code': '',
    'product': '',
    'machine': '',
    'job_code': '',
    'isError': '',
    'shipment_date': [],
});
// 属性
const props = defineProps({
    isErpSync: {
        type: Boolean,
        default: false,
    },
});
// 基础数据
const orderList = computed(() => {
    const {order_code, product, job_code, machine, isError, shipment_date} = keywords.value;
    const _orderCode = order_code.trim();
    const _product = product.trim();
    const _jobCode = job_code.trim();
    return uWbStore.orderList.filter((item) => {
        const matchesOrderCode = !_orderCode || item.order_code.includes(_orderCode);
        const matchesProductName = !_product || item?.product?.includes(_product);
        const matchesJobCode = !_jobCode || item?.job_code?.includes(_jobCode);
        const matchesMachine = !machine || item?.machine?.includes(machine);
        const matchShip = shipment_date && shipment_date.length === 2
            ? dayjs(shipment_date[0]).diff(dayjs(item.shipment_date), 'day') <= 0
        && dayjs(item.shipment_date).diff(dayjs(shipment_date[1]), 'day') <= 0
            : true;
        const filterError = isError === ''
            ? true
            : (isError === '0' ? item.error.length === 0 : item.error.length > 0);
            // 只有当所有检查都通过时，才返回 true
        return matchesOrderCode && matchesProductName && matchesJobCode
            && matchesMachine && filterError
            && matchShip;
    });
});
// #endregion vue初始化

// #region vTavle 初始化,事件绑定
const vTableRef = ref(null);

// 右键菜单
const handleDropdownMenuClick = (args) => {
    const {menuKey, row, col} = args;
    const vTableInstance = vTableRef.value.vTableInstance;
    if (menuKey === 'copyToClipboard') {
        const _text = vTableInstance.getCellOriginValue(col, row);
        navigator.clipboard.writeText(_text);
    }
    if (menuKey === 'edit') {
        editOrder(vTableInstance.getRecordByCell(col, row));
        return;
    }
    if (menuKey === 'delete') {
        deleteOrder(vTableInstance.getRecordByCell(col, row));
    }
};

const handleClickCell = (args) => {
    const {field, target, originData, cellLocation, row} = args;
    console.log(field, target, originData, args);
    if (field === 'checked' && target.name === 'checkbox' && cellLocation === 'body') {
        originData.checked = !originData.checked;
        return;
    }
    if (field === 'checked' && target.name === 'checkbox' && cellLocation === 'columnHeader') {
        orderList.value.forEach((item) => {
            item.checked = target.attribute.checked;
        });
        return;
    }
    // 产品信息同步或编辑
    if (field === 'product' && target.type === 'image' && row > 0) {
        if (target.name === 'refresh') {
            // 同步
            syncFromERP(originData);
            return;
        }
        if (target.name === 'edit') {
            // 编辑
            syncFromERP(originData, 'edit');
            return;
        }
        if (target.name === 'plus') {
            // 编辑
            syncFromERP(originData, 'add');
        }
    }
    // 处理任务编辑逻辑
    if (field === 'op' && target.type === 'image' && row > 0) {
        if (target.attribute.image === sEdit.value) {
            editOrder(originData);
            return;
        }
        if (target.attribute.image === sDelete.value) {
            deleteOrder(originData);
            return;
        }
        if (target.attribute.image === sOp.value) {
            addToJob(originData);
            return;
        }
    }
    if (field === 'op' && target.type === 'image' && row === 0) {
        if (target.name === 'plus') {
            // 编辑
            addOrder();
            return;
        }
        if (target.name === 'delete') {
            delOrders();
        }
    }
};
const handleMouseEnterCell = (args) => {
    const {col, row} = args;
    // 序号列
    const vTableInstance = vTableRef.value.vTableInstance;
    if (col === 1 && row > 0) {
        const record = vTableInstance.records[row - 1];
        if (record.error.length === 0) return;
        const rect = vTableInstance.getVisibleCellRangeRelativeRect({col, row});
        vTableInstance.showTooltip(col, row, {
            content: '错误信息：' + record.error.map((err) => err.info).join('|'),
            referencePosition: {rect, placement: VTable.TYPES.Placement.right}, // TODO
            disappearDelay: 100,
            style: {
                bgColor: 'red',
                color: 'white',
                font: 'normal bold normal 14px/1 STKaiti',
                arrowMark: true,
            },
        });
    }
};
const vtableOptions = {
    defaultRowHeight: 45,
    widthMode: 'standard',
    tooltip: {
        isShowOverflowTextTooltip: true,
    },
    rightFrozenColCount: 1,
    dragHeaderMode: 'column',
    emptyTip: {text: '暂无数据'},
    theme: VTable.themes.DEFAULT.extends({
        headerStyle: {
            textAlign: 'center',
            fontSize: 14,
        },
        bodyStyle: {
            fontSize: 14,
            textAlign: 'left',
        },
        scrollStyle: {
            hoverOn: false,
            barToSide: false,
        },
    }),
    menu: {
        contextMenuItems: (field, row) => {
            const contextKeys = ['product', 'order_code'];
            if (contextKeys.indexOf(field) === -1 || row === 0) return;
            return [
                {text: '复制', menuKey: 'copyToClipboard'},
                {text: '编辑', menuKey: 'edit'},
                {text: '删除', menuKey: 'delete'},
            ];
        },
    },
};
// #endregion VTableConfig

// #region 产品同步添加编辑
const refProductOpt = ref(null);
const syncFromERP = async (row, model = 'syncErp') => {
    let prdData;
    if (model === 'syncErp') {
        const prd = splitStringByLastColon(row.product_query);
        const prdinfo = await getErpPrd(prd.p_code, prd.v_name);
        if (prdinfo.length === 0) {
            notify('error', '同步出现错误');
            return;
        }
        const _remote = await getPrdInfo(row.product);
        if (_remote.length > 0) {
            // 判断是否有同名的产品
            const sameNamePrd = _remote.find((prd) => {
                return prd.name === row.product;
            });
            if (sameNamePrd) {
                notify('error', `与产品编码:${sameNamePrd.code}的产品的名称冲突,注意ERP系统中的同名产品与自动排产中的编号是否一致`);
                return;
            }
        }
        // 由于历史原因或者先创建产品,这里可能会出现同名的产品信息
        prdinfo[0].code = `${prdinfo[0].code}:${prd.v_name}`;
        prdData = prdinfo[0];
    }
    if (model === 'edit') {
        const _remote = await getPrdInfo(row.product_info.code);
        if (_remote.length === 0) {
            notify('error', `编辑出现错误,没有找到编号:${row.product_info.code}的产品,请刷新页面再次尝试`);
            return;
        }
        prdData = _remote[0];
        if (props.isErpSync && prdData.name !== row.product) {
            prdData.name = row.product;
        }
    }
    if (model === 'add') {
        prdData = {'name': row.product};
    }
    const data = await refProductOpt.value.show({
        title: '产品同步添加',
        data: prdData,
        product_list: [],
    });
    if (data) {
        // 刷新
        row.product_info = data;
        row.product = row.product_info.name;
        await uWbStore.updateOrder(row.serial_number, row);
        // 同步处理其他地方的相同的产品的错误信息
        // 处理订单信息的
        const orders = uWbStore.orderList.filter((item) => {
            return (item.product === row.product_info.name
                    || item.product === row.product_info.code) && item.serial_number !== row.serial_number;
        });
        const jobes = uWbStore.jobList.filter((item) => {
            return (item.product === row.product_info.name
            || item.product === row.product_info.code);
        });
        await uWbStore.updatePrdInfo(orders, row.product_info);
        await uWbStore.updatePrdInfo(jobes, row.product_info);
        uWbStore.checkOrder(orders);
        uWbStore.checkJob(jobes);
    }
};
/**
 * 通过最后一个冒号（':'）将产品字符串分割为两部分。
 *
 * 此函数通常用于将产品代码与其变体名称分开。
 * 例如，如果输入是 "ABC123:Red"，它将返回一个对象，其中 `p_code` 为 "ABC123"，
 * `v_name` 为 "Red"。如果字符串中没有冒号，则整个字符串被视为产品代码（`p_code`），
 * 变体名称（`v_name`）设置为空字符串。
 *
 * @param {string} product - 要分割的产品字符串，可能包含冒号。
 * @returns {Object} 包含两个属性的对象：
 *   - `p_code`: 产品代码，即最后一个冒号之前的部分。
 *   - `v_name`: 变体名称，即最后一个冒号之后的部分。如果不存在冒号，则为空字符串。
 */
const splitStringByLastColon = (product) => {
    const parts = product.split(':');
    if (parts.length > 1) {
        const p_code = parts.slice(0, -1).join(':');
        const v_name = parts[parts.length - 1];
        return {
            p_code,
            v_name,
        };
    } else {
        return {
            p_code: product,
            v_name: '',
        };
    }
};
const isPrdNotFound = ({error}) => {
    return error.findIndex((err) => {
        return err.tag === 'product-not-found';
    }) > -1;
};
const prdSyncIcon = (args) => {
    const {row, table} = args;
    if (isPrdNotFound(table.records[row - 1])) {
        if (props.isErpSync) {
            return refreshIcon;
        }
        return plusIcon;
    } else {
        const _record = table.records[row - 1];
        if (props.isErpSync && _record?.product_info && _record.product !== _record.product_info?.name) {
            return [editIcon, questionError];
        }
        return editIcon;
    }
};
// #endregion 产品同步添加编辑

// #region 数据处理函数

/**
 * 根据任务记录获取鼠标指针状态。
 *
 * @param {Object} record - 任务记录，包含完成状态和错误信息。
 * @param {string} record.is_finished - 任务的完成状态。
 * @param {Array} record.error - 任务的错误信息列表。
 * @returns {string} - 鼠标指针状态（如 'not-allowed' 或 'pointer'）。
 */
const cursorState = (record) => {
    if (isAddJobEnabled(record)) {
        return 'pointer';
    } else {
        return 'not-allowed';
    }
};

/**
 * 根据任务记录计算错误颜色。
 *
 * @param {Object} args - 参数对象，包含行索引。
 * @param {number} args.row - 行索引。
 * @returns {string} - 错误颜色（如 '#f56c6c'）。
 */
const calcErrorColor = (args) => {
    if (orderList.value[args.row - 1].error.length > 0) {
        return '#f56c6c';
    }
};
// #endregion 数据处理函数

// #region 排序处理
/**
 * 处理排序逻辑。
 *
 * @param {any} v1 - 第一个值。
 * @param {any} v2 - 第二个值。
 * @param {string} order - 排序顺序（如 'asc' 或 'desc'）。
 * @returns {number} - 排序结果（-1、0 或 1）。
 */
const onSort = (v1, v2, order) => {
    if (order === 'desc') {
        return v1 === v2 ? 0 : v1 > v2 ? -1 : 1;
    }
    return v1 === v2 ? 0 : v1 > v2 ? 1 : -1;
};
// #endregion

// #region 订单操作
const delOrders = () => {
    const toDelOrders = orderList.value.filter((item) => item.checked);
    ElMessageBox.confirm(
        `删除${toDelOrders.length}行数据？`,
        '提醒',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        },
    )
        .then(() => {
            toDelOrders.forEach((item) => {
                uWbStore.deleteOrder(item.serial_number);
            });
            notify('success', '删除成功', 1500);
        })
        .catch((msg) => {
            notify('info', msg === 'cancel' ? '取消' : msg, 1500);
        });
};
// 添加订单
const addOrder = async () => {
    const _tpl = uWbStore.getEmpyRow('orderList');
    autoTabluar.doOrderFmtData(_tpl);
    const res = await refOrderEdit.value.show({
        title: '订单添加',
        data: _tpl,
    });
    if (res) {
        // 添加数据
        uWbStore.addOrder([res]);
    }
};
const refOrderEdit = ref(null);
const editOrder = async (row) => {
    const res = await refOrderEdit.value.show({
        title: '订单修改',
        data: Object.assign({}, row),
    });
    if (res) {
        // 更新数据
        uWbStore.updateOrder(row.serial_number, res);
    }
};
const deleteOrder = (row) => {
    ElMessageBox.confirm(
        `删除本行数据？${row.order_code},同步删除任务与排产`,
        '提醒',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        },
    )
        .then(() => {
            uWbStore.deleteOrder(row.serial_number);
            notify('success', '删除成功', 1500);
        })
        .catch((msg) => {
            notify('info', msg === 'cancel' ? '取消' : msg, 1500);
        });
};
// #endregion 订单操作

// #region 订单拆分成任务单

// 拆分订单
const jobInfo = ref([]);
const orderInfo = ref({});
const showJobSplit = ref(false);
const splitProcess = ['print'];
/**
 * 根据传入的参数过滤拆分的任务列表信息
 * @param {array} jobList
 * @param {string[]|string} rule -['print']|all|process_cate_code
 * @returns {array}
 */
const jobsfilter = (jobList, rule = ['print']) => {
    if (rule === 'all') {
        return jobList;
    } else if (Array.isArray(rule)) {
        return jobList.filter(job => rule.includes(job.cate_code));
    } else {
        return jobList.filter(job => job.cate_code === rule);
    }
};
const addToJob = async (row) => {
    const _orderData = uWbStore.getOrder(row.serial_number);
    const {
        product_info: {name},
        quantity, shipment_date,
    } = _orderData;
    try {
        // 已最迟日期来进行推算
        const [craftList, estimateSchLast] = await estimateProductPlan(name, shipment_date, quantity, 'post');
        // 拆分好的任务单列表
        const splitJobList = craftList.map((craft) => {
            if (estimateSchLast.has(craft.name)) {
                // job.earlist_date = estimateSchLast.get(job.name).begin;
                const _es = estimateSchLast.get(craft.name);
                craft.shipment_date = dayjs(_es.end, 'YYYY-MM-DD');
                craft.time = _es.producedminutes / 60;
                craft.produce_quantity = craft.cate_code === 'mkbag'
                    ? _es.processQuantity.output
                    : _es.processQuantity.input;
                craft.produce_quantity = Math.floor(craft.produce_quantity);
            }
            return craft;
        });
        // 替换joblist中的 earlist_date,latest_finished
        // 本期只开发印刷任务
        // 判单任务列表是否存在相应的任务
        const _jobL = jobsfilter(splitJobList, splitProcess)
            .filter((item) => {
                return (orderJobObj[_orderData.order_code] || []).findIndex((pname) => {
                    return pname === item.name;
                }) === -1;
            });
        if (_jobL.length === 0) {
            notify('info', '没有需要拆分的任务', 3000);
            return;
        }
        // 对joblist进行处理,过滤已有的任务
        jobInfo.value = [..._jobL];
        // this.jobInfo = [...joblist];
        // this.jobInfo = joblist.filter((item) => item.cate_code === 'print');
        // 对jobinfo进行处理,保证数量都为整数
        orderInfo.value = {..._orderData};
        showJobSplit.value = true;
    } catch (e) {
        console.log('订单拆分出现错误!', e);
        notify(e);
    }
};
const isAddJobEnabled = (row) => {
    if (row.forbidSplit) return false;
    if (row.error.length > 0) return false;
    if (row.is_finished === '是') return false;
    return true;
};
const orderJobObj = computed(() => {
    return uWbStore.jobList.reduce((acc, cur) => {
        if (!Object.hasOwn(acc, cur.order_code)) {
            acc[cur.order_code] = [];
        }
        acc[cur.order_code].push(cur.process_name);
        return acc;
    }, {});
});
watch(orderJobObj, (val) => {
    console.log('watch', val);
    uWbStore.orderList.forEach((item) => {
        if (val?.[item.order_code]) {
            item.forbidSplit = jobsfilter(item.product_info?.craft || [], splitProcess).reduce((acc, cur) => {
                return acc && val[item.order_code].findIndex((pname) => {
                    return pname === cur.name;
                }) !== -1;
            }, true);
        } else {
            item.forbidSplit = false;
        }
    });
});

// 确认拆分的image的状态
const addJobImage = (record) => {
    return isAddJobEnabled(record) ? sOp.value : sOpDisable.value;
};
// #endregion 订单拆分成任务单

// #region 表头操作列处理
const isCheckedRows = computed(() => {
    return uWbStore.orderList.filter((item) => item.checked).length > 0;
});
const opHeaderIcon = () => {
    if (isCheckedRows.value) {
        return [deleteIcon, plusIcon];
    }
    return plusIcon;
};
// #endregion 表头操作列

// #region 模拟switch,完成|参与计算
const switchFinishedState = (e, record) => {
    if (record.is_finished === '是') {
        record.is_finished = '否';
    } else {
        record.is_finished = '是';
    }
    e.stopPropagation();
};
// #endregion

</script>
<style scoped>
.table-container {
    height: calc(100% - 50px);
    width:100%;
}
</style>
